var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.data.column.field === "name"
      ? _c("span", { staticClass: "dark-gray-color" }, [
          _c(
            "div",
            { staticClass: "name__wrapper" },
            [_c("ReadMore", { attrs: { text: _vm.data.row.name } })],
            1
          ),
        ])
      : _vm.data.column.field === "description"
      ? _c(
          "span",
          [_c("ReadMore", { attrs: { text: _vm.data.row.description } })],
          1
        )
      : _vm.data.column.field === "insertDateTime"
      ? _c("span", [_c("DateColumn", { attrs: { data: _vm.data } })], 1)
      : _vm.data.column.field === "actions"
      ? _c(
          "span",
          { staticClass: "properties__actions" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.property-sdk",
                    modifiers: { "property-sdk": true },
                  },
                ],
                staticClass: "property-sdk__button",
                attrs: { id: "tracking-code-property" },
                on: {
                  click: () =>
                    _vm.$store.commit("properties/SET_PROPERTY", _vm.data.row),
                },
              },
              [_c("GjIcon", { attrs: { name: "Embed", size: "24" } })],
              1
            ),
            _c("DropdownActions", {
              attrs: { id: "properties-actions", items: _vm.dropdownActions },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }