var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.typeOfGrid === "invitations"
          ? "user-roles__custom-search group-table d-flex align-right"
          : "user-roles__custom-search group-table flex-elem",
    },
    [
      _vm.typeOfGrid !== "invitations"
        ? _c("div", [
            _vm.typeOfGrid === "organization"
              ? _c(
                  "div",
                  { attrs: { id: "create-organization-user__wrapper" } },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-lg-add-user",
                            modifiers: { "modal-lg-add-user": true },
                          },
                        ],
                        staticClass: "create__button",
                        attrs: {
                          id: "create-organization-user",
                          variant: "gradient-primary",
                          disabled: !_vm.role || _vm.isLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetRowToEdit("Organization")
                          },
                        },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "mr-50",
                          attrs: { name: "Plus", size: "18" },
                        }),
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("accountManagement.userRoles.addUser")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    !_vm.role
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "create-organization-user__wrapper",
                              triggers: "hover",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("permissionMessage")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeOfGrid === "properties"
              ? _c(
                  "div",
                  { attrs: { id: "create-property-user__wrapper" } },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-lg-add-user",
                            modifiers: { "modal-lg-add-user": true },
                          },
                        ],
                        staticClass: "create__button",
                        attrs: {
                          id: "create-property-user",
                          variant: "gradient-primary",
                          disabled: !_vm.role || _vm.isLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetRowToEdit("Properties")
                          },
                        },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "mr-50",
                          attrs: { name: "Plus", size: "18" },
                        }),
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("accountManagement.userRoles.addUser")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    !_vm.role
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "create-property-user__wrapper",
                              triggers: "hover",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("permissionMessage")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeOfGrid === "apikeys"
              ? _c(
                  "div",
                  { attrs: { id: "create-apiKey__wrapper" } },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-modal",
                            rawName: "v-b-modal.modal-lg-add-user",
                            modifiers: { "modal-lg-add-user": true },
                          },
                        ],
                        staticClass: "create__button",
                        attrs: {
                          id: "create-apiKey",
                          disabled: !_vm.role || _vm.isLoading,
                          variant: "gradient-primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.resetRowToEdit("APIKeys")
                          },
                        },
                      },
                      [
                        _c("GjIcon", {
                          staticClass: "mr-50",
                          attrs: { name: "Plus", size: "18" },
                        }),
                        _c("span", { staticClass: "align-middle" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("accountManagement.userRoles.addApiKey")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    !_vm.role
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "create-apiKey__wrapper",
                              triggers: "hover",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("permissionMessage")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("SearchInputField", {
        attrs: { id: `search-${_vm.typeOfGrid}`, data: _vm.datasource },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }