var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "apiKeyModal",
        "modal-class": "api-keys__modal",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      _c("div", { staticClass: "api-keys__modal--title" }, [
        _c(
          "div",
          { staticClass: "api-header__image" },
          [_c("GjIcon", { attrs: { name: "Checkmark_fill", size: "68" } })],
          1
        ),
        _c("span", [
          _vm._v(_vm._s(_vm.$t("accountManagement.userRoles.apiKeyGenerated"))),
        ]),
      ]),
      _c("div", { staticClass: "api-keys__modal--key" }, [
        _c("div", [_vm._v("API Key")]),
        _c("div", { staticClass: "api-key__content" }, [
          _vm._v(" " + _vm._s(_vm.token) + " "),
        ]),
        _c(
          "div",
          {
            staticClass: "api-key__copy",
            attrs: { id: "copy-apiKey" },
            on: {
              click: function ($event) {
                return _vm.copyAPIKey(_vm.token)
              },
            },
          },
          [
            _c("GjIcon", {
              staticClass: "mr-50",
              attrs: { name: "Copy", size: "18" },
            }),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("accountManagement.userRoles.copyApiKey"))),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "api-keys__modal--info" }, [
        _c("div", { staticClass: "api-info__title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("accountManagement.userRoles.keepApiKeySafe")) +
              " "
          ),
        ]),
        _c("span", [
          _vm._v(
            _vm._s(_vm.$t("accountManagement.userRoles.apiKeyGeneratedInfo")) +
              "."
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }