// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__organization .create-property__container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.bisko-app__organization .create-property__container .error__info-message {
  font-size: 13px;
  color: #e34850;
  height: 18px;
}
.bisko-app__organization .create-property__container > form {
  width: 50%;
}
@media (max-width: 600px) {
.bisko-app__organization .create-property__container > form {
    width: 100%;
}
}
.bisko-app__organization .create-property__container hr {
  border: 1px solid #E0E5EB;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 36px;
}
.bisko-app__organization .create-property__container__row-button {
  position: absolute;
  bottom: 0;
  width: 50%;
}
@media (max-width: 600px) {
.bisko-app__organization .create-property__container__row-button {
    width: 100%;
}
}
.bisko-app__organization .create-property__container__row-button > div {
  display: flex;
  justify-content: flex-end;
}
.bisko-app__organization .create-property__container__row-button > div > button:first-child {
  margin-right: 16px;
}
.bisko-app__organization .loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
