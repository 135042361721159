import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import properties from './properties'
import users from './users'

const routes = [
  {
    path: '/bisko/:organizationId/organization',
    redirect: '/bisko/:organizationId/organization/properties'
  },
  ...properties,
  ...users,
  {
    path: '/bisko/:organizationId/organization/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
