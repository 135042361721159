import {whitelabel} from '.';

const generateSdkSnippet = (
  organizationCode = 'gjirafa',
  propertyId = '11111'
) => ({
  WEB:
    whitelabel.title === 'integr8'
      ? `<script async src="https://cdn.sea.integr8.digital/web/integr8-sdk.js"></script> \n` +
        '<script>\n' +
        '\twindow.integr8Queue = window.integr8Queue || [];\n' +
        '\tfunction integr8(){integr8Queue.push(arguments);}\n' +
        `\tintegr8('init', '${propertyId}');\n` +
        `\tintegr8('time');\n` +
        `\tintegr8('event', 'pageview');\n` +
        '</script>'
      : `<script async src="https://bisko.gjirafa.net/web/${organizationCode}-sdk.js"></script> \n` +
        '<script>\n' +
        '\twindow.biskoQueue = window.biskoQueue || [];\n' +
        '\tfunction bsk(){biskoQueue.push(arguments);}\n' +
        `\tbsk('init', '${propertyId}');\n` +
        `\tbsk('time');\n` +
        `\tbsk('event', 'pageview');\n` +
        '</script>',
  IOS: {
    Swift:
      whitelabel.title === 'integr8'
        ? `Integr8.init(propertyId: ${propertyId})\nIntegr8.event(eventType: "read")`
        : `Bisko.init(propertyId: ${propertyId})\nBisko.event(eventType: "read")`,
    'Objective-C':
      whitelabel.title === 'integr8'
        ? `[[Integr8 alloc] initWithPropertyId:${propertyId}];\n[Integr8 eventWithEventType:@"read"];`
        : `[[Bisko alloc] initWithPropertyId:${propertyId}];\n[Bisko eventWithEventType:@"read"];`,
  },
  ANDROID: {
    Kotlin:
      whitelabel.title === 'integr8'
        ? `Integr8.init(context = this, propertyId = ${propertyId})\nIntegr8.event("read")`
        : `Bisko.init(context = this, propertyId = ${propertyId})\nBisko.event("read")`,
    Java:
      whitelabel.title === 'integr8'
        ? `Integr8.Companion.init(this, ${propertyId});\nIntegr8.Companion.event("read");`
        : `Bisko.Companion.init(this, ${propertyId});\nBisko.Companion.event("read");`,
  },
  AMP: `<script async custom-element="amp-analytics" src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"></script>
  <amp-analytics type="bisko" id="bisko">
  <script type="application/json">
  {
      "transport": {
          "beacon": true,
          "xhrpost": false
      },
      "requests": {
        "pageview": "${
          whitelabel.title === 'integr8'
            ? 'https://dmp.sea.integr8.digital/'
            : 'https://bisko.gjirafa.tech/'
        }api/v1/amp?Type=\${onViewType}&PropertyId=\${propertyId}&ScreenHeight=\${screenHeight}&ScreenWidth=\${screenWidth}&Language=\${browserLanguage}&PageDomain=\${canonicalHostname}&Url=\${canonicalUrl}&PageCleanUrl=\${canonicalHost}&Title=\${title}&Referrer=\${documentReferrer}&PageViewGuid=\${pageViewId64}&ClientDateTime=\${timestamp}&DateTimeUtcOffset=\${timezone}"
    },
      "triggers": {
          "trackPageview": {
              "on": "visible",
              "request": "pageview"
          }
      },
      "vars": {
          "propertyId": ${propertyId},
          "onViewType": "pageview"
      }
  }
  </script>
</amp-analytics>`,
});

export default generateSdkSnippet;
