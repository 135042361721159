<template>
  <section v-if="!isLoading" class="form__wrapper">
    <div class="create-property__container">
      <b-form novalidate class="properties-form__container" @submit.prevent>
        <b-form-row class="properties-form__row-container">
          <b-col lg="12" class="input__wrapper">
            <label for="Organization">{{ $t('Organization') }} *</label>
            <b-form-input
              id="readOnlyInput"
              class="form__input form__input--dark"
              :value="organizationName"
              disabled
            />
          </b-col>

          <b-col lg="12" class="input__wrapper">
            <label for="input-invalid1">{{ $t('Name') }} *</label>
            <b-form-input
              id="property-name"
              v-model.trim="propertyObject.name.value"
              :placeholder="$t('Enter application name here')"
              class="form__input"
              :class="{
                'form__input--invalid': propertyObject.name.errors.length > 0,
              }"
              @input="handleFormInput(formState, [propertyObject.name])"
            />
            <div
              v-for="(error, errIndex) in propertyObject.name.errors"
              :key="errIndex"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>

          <b-col lg="12" class="input__wrapper">
            <label for="textarea-default">{{ $t('Description') }} *</label>
            <b-form-textarea
              id="property-description"
              v-model.trim="propertyObject.description.value"
              :placeholder="$t('Enter application description here')"
              rows="2"
              no-resize
              class="form__input form__input-textarea"
              :class="{
                'form__input--invalid':
                  propertyObject.description.errors.length > 0,
              }"
              @input="handleFormInput(formState, [propertyObject.description])"
            />
            <div
              v-for="(error, errIndex) in propertyObject.description.errors"
              :key="errIndex"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </b-col>
        </b-form-row>
      </b-form>
    </div>

    <Footer
      :is-loading="formState.isLoading"
      :submitted="formState.isLoading"
      :cancel-id="`cancel-create-property`"
      :create-id="`add-property`"
      :edit-mode="!!propertyId"
      @back="cancel()"
      @create="!propertyId ? createProperty() : triggerSaveModal()"
    />

    <SaveChangesModal :type="$t('property')" @save="createProperty()" />
  </section>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import { properties } from '@/api'
import router from '@/router'
import store from '@/store'
import {
  Footer,
  LoadingBar,
  SaveChangesModal,
  confirmChanges,
  handleFormInput,
} from '@nodus/utilities-front'
import { BCol, BForm, BFormInput, BFormRow, BFormTextarea } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue'

export default {
  components: {
    BFormInput,
    BForm,
    BFormRow,
    BCol,
    BFormTextarea,
    Footer,
    SaveChangesModal,
    LoadingBar,
  },
  async beforeRouteEnter(to, __, next) {
    if (!to.params.propertyId) {
      if (store.getters['properties/getCanAdd'] === null) {
        await store.dispatch('properties/getProperties', {
          id: to.params.organizationId,
        })
      }
      if (store.getters['properties/getCanAdd']) next()
      else next('/error/401')
    } else {
      next()
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.formState.isSubmitted && !this.formState.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const { propertyId } = vm.$route.params
    const organizationName = computed(
      () => store.getters['properties/organizationName']
    )
    const isLoading = ref(false)

    const formState = reactive({
      isSubmitted: false,
      isLoading: false,
      isPristine: true,
    })

    const propertyObject = reactive({
      name: {
        value: '',
        errors: [],
      },
      description: {
        value: '',
        errors: [],
      },
    })

    onMounted(async () => {
      if (propertyId) {
        isLoading.value = true
        const response = await store.dispatch('properties/getProperty', {
          id: organizationId,
          propertyId,
        })
        propertyObject.name.value = response?.property?.name
        propertyObject.description.value = response?.property?.description
        isLoading.value = false
      }
    })

    const cancel = () => {
      router.push({ name: 'properties' })
    }

    const createProperty = async () => {
      formState.isLoading = true

      const requestObject = {
        name: propertyObject?.name?.value,
        description: propertyObject?.description?.value,
      }

      let response = null
      if (propertyId) {
        response = await properties.update(
          organizationId,
          propertyId,
          requestObject
        )
      } else {
        response = await properties.addNew(organizationId, requestObject)
      }

      const { errors, success } = response.data

      if (!success) {
        formState.isSubmitted = false
        Object.keys(errors).forEach((key) => {
          propertyObject[key].errors = errors[key]
        })
      } else {
        formState.isSubmitted = true
        cancel()
      }
      formState.isLoading = false
    }

    const triggerSaveModal = () => {
      if (formState.isPristine)
        router.push({
          name: 'properties',
          params: { organizationId },
        })
      else vm.$bvModal.show('save-changes-modal')
    }

    return {
      handleFormInput,
      formState,
      propertyObject,
      organizationName,
      isLoading,
      createProperty,
      cancel,
      triggerSaveModal,
      propertyId,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/views/properties/property-create.scss';
</style>
