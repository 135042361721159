var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "property-sdk",
        "modal-class": "modal__body__text--left tracking-code__header",
        "ok-only": "",
        "ok-title": "Close",
        "ok-variant": "outline-secondary",
        centered: "",
        size: "lg",
        title: `${_vm.$t("accountManagement.trackingCode")} \n ${
          _vm.modalData.name
        }`,
        "no-enforce-focus": "",
        "no-close-on-backdrop": "",
      },
    },
    [
      _c("div", { staticClass: "properties-list__modal-content" }, [
        _c(
          "div",
          { staticClass: "properties-list__modal-content-id" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("id").toUpperCase()))]),
            _c("b-form-input", {
              staticClass: "form__input--dark",
              attrs: {
                value: _vm.webSdkId,
                readonly: "",
                id: "copy-property-id",
              },
              on: {
                click: function ($event) {
                  return _vm.doCopy(_vm.webSdkId, "Property id")
                },
              },
            }),
            _c("GjIcon", {
              staticClass: "mr-50",
              attrs: { name: "Copy", size: "22" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "properties-list__modal-content-code" },
          [
            _c(
              "b-tabs",
              { attrs: { align: "left" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: {
                      id: "web",
                      active: "",
                      title: _vm.$t("accountManagement.Web"),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "documentation__info-text mb-1" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "accountManagement.webSdkDocumentationLink"
                              )
                            ) + " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.whitelabel.documentationUrl}/websdk`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Documentation").toLowerCase())
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ]),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "properties-list__modal-content-code-trackingcode",
                      },
                      [_vm._v(_vm._s(_vm.$t("accountManagement.trackingCode")))]
                    ),
                    _c("pre", [
                      _vm._v("                "),
                      _c("code", {
                        staticClass: "properties__tracking-code",
                        domProps: {
                          textContent: _vm._s(_vm.displaySdkSnippet.WEB),
                        },
                      }),
                      _vm._v("\n              "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "properties-list__modal-content-code-copycode",
                        attrs: { id: "copy-web-code" },
                        on: {
                          click: function ($event) {
                            return _vm.doCopy(
                              _vm.displaySdkSnippet.WEB,
                              "Bisko Web SDK"
                            )
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("accountManagement.copyToClipboard"))
                          ),
                        ]),
                        _c("GjIcon", {
                          staticClass: "mr-50",
                          attrs: { name: "Copy", size: "22" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      id: "ios",
                      title: _vm.$t("accountManagement.iOS"),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "documentation__info-text mb-50 ml-50" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "accountManagement.iosSdkDocumentationLink"
                              )
                            ) + " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.whitelabel.documentationUrl}/mobilesdk#bisko-ios-sdk`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Documentation").toLowerCase())
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ]),
                      ]
                    ),
                    _vm._l(
                      Object.keys(_vm.displaySdkSnippet.IOS),
                      function (data) {
                        return _c(
                          "div",
                          { key: data, staticClass: "mobile__wrapper" },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "accordion-" + data,
                                    expression: "'accordion-' + data",
                                  },
                                ],
                                staticClass:
                                  "presets-container__expandable-heading mobile__wrapper-heading",
                              },
                              [_vm._v(" " + _vm._s(data) + " ")]
                            ),
                            _c(
                              "b-collapse",
                              { attrs: { id: `accordion-${data}` } },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "properties-list__modal-content-code-trackingcode",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accountManagement.trackingCode"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("pre", [
                                    _vm._v("                      "),
                                    _c("code", {
                                      staticClass: "properties__tracking-code",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.displaySdkSnippet.IOS[data]
                                        ),
                                      },
                                    }),
                                    _vm._v("\n                    "),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "properties-list__modal-content-code-copycode",
                                      attrs: { id: `copy-${data}-code` },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doCopy(
                                            _vm.displaySdkSnippet.IOS[data],
                                            `Bisko ${data} IOS SDK`
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "accountManagement.copyToClipboard"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("GjIcon", {
                                        staticClass: "mr-50",
                                        attrs: { name: "Copy", size: "22" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      id: "android",
                      title: _vm.$t("accountManagement.Android"),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "documentation__info-text mb-50 ml-50" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "accountManagement.androidSdkDocumentationLink"
                              )
                            ) + " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.whitelabel.documentationUrl}/mobilesdk#bisko-android-sdk`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Documentation").toLowerCase())
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ]),
                      ]
                    ),
                    _vm._l(
                      Object.keys(_vm.displaySdkSnippet.ANDROID),
                      function (data) {
                        return _c(
                          "div",
                          { key: data, staticClass: "mobile__wrapper" },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "accordion-" + data,
                                    expression: "'accordion-' + data",
                                  },
                                ],
                                staticClass:
                                  "presets-container__expandable-heading mobile__wrapper-heading",
                              },
                              [_vm._v(" " + _vm._s(data) + " ")]
                            ),
                            _c(
                              "b-collapse",
                              { attrs: { id: `accordion-${data}` } },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "properties-list__modal-content-code-trackingcode",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "accountManagement.trackingCode"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("pre", [
                                    _vm._v("                      "),
                                    _c("code", {
                                      staticClass: "properties__tracking-code",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.displaySdkSnippet.ANDROID[data]
                                        ),
                                      },
                                    }),
                                    _vm._v("\n                    "),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "properties-list__modal-content-code-copycode",
                                      attrs: { id: `copy-${data}-code` },
                                      on: {
                                        click: function ($event) {
                                          return _vm.doCopy(
                                            _vm.displaySdkSnippet.ANDROID[data],
                                            `Bisko ${data} Android SDK`
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "accountManagement.copyToClipboard"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("GjIcon", {
                                        staticClass: "mr-50",
                                        attrs: { name: "Copy", size: "22" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "b-tab",
                  {
                    attrs: {
                      id: "amp",
                      title: _vm.$t("accountManagement.Amp"),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "documentation__info-text mb-1" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "accountManagement.ampSdkDocumentationLink"
                              )
                            ) + " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.whitelabel.documentationUrl}/ampsdk`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Documentation").toLowerCase())
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ]),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "properties-list__modal-content-code-trackingcode",
                      },
                      [_vm._v(_vm._s(_vm.$t("accountManagement.trackingCode")))]
                    ),
                    _c("pre", [
                      _vm._v("                "),
                      _c("code", {
                        staticClass:
                          "properties__tracking-code amp__tracking-code",
                        domProps: {
                          textContent: _vm._s(_vm.displaySdkSnippet.AMP),
                        },
                      }),
                      _vm._v("\n              "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "properties-list__modal-content-code-copycode",
                        attrs: { id: "copy-amp-code" },
                        on: {
                          click: function ($event) {
                            return _vm.doCopy(
                              _vm.displaySdkSnippet.AMP,
                              "Bisko AMP SDK"
                            )
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("accountManagement.copyToClipboard"))
                          ),
                        ]),
                        _c("GjIcon", {
                          staticClass: "mr-50",
                          attrs: { name: "Copy", size: "22" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }