<template>
  <b-modal
    id="apiKeyModal"
    modal-class="api-keys__modal"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <div class="api-keys__modal--title">
      <div class="api-header__image">
        <GjIcon name="Checkmark_fill" size="68" />
      </div>
      <span>{{ $t('accountManagement.userRoles.apiKeyGenerated') }}</span>
    </div>
    <div class="api-keys__modal--key">
      <div>API Key</div>
      <div class="api-key__content">
        {{ token }}
      </div>
      <div id="copy-apiKey" class="api-key__copy" @click="copyAPIKey(token)">
        <GjIcon name="Copy" class="mr-50" size="18"></GjIcon>
        <span>{{ $t('accountManagement.userRoles.copyApiKey') }}</span>
      </div>
    </div>
    <div class="api-keys__modal--info">
      <div class="api-info__title">
        {{ $t('accountManagement.userRoles.keepApiKeySafe') }}
      </div>
      <span>{{ $t('accountManagement.userRoles.apiKeyGeneratedInfo') }}.</span>
    </div>
  </b-modal>
</template>

<script>
import { GjIcon, showToast } from '@nodus/utilities-front'
import { useClipboard } from '@vueuse/core'
import { BModal } from 'bootstrap-vue'

export default {
  name: 'APIKeyModal',
  components: {
    BModal,
    GjIcon
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup() {
    const { copy } = useClipboard()

    const copyAPIKey = (apiKey) => {
      copy(apiKey)
      showToast('success', 'API Key copied')
    }

    return {
      copyAPIKey
    }
  }
}
</script>
