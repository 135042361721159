var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("primary-create-button", {
            attrs: {
              id: "create-property",
              name: _vm.$t("accountManagement.newProperty"),
              "is-loading": _vm.isLoading,
              "can-add": _vm.canAddProperty,
              "click-handler": _vm.addNewProperty,
            },
          }),
          _c("SearchInputField", {
            attrs: {
              id: "search-properties",
              data: _vm.allProperties,
              debounce: "400",
            },
          }),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "table__wrapper" },
        [
          _c("CustomTable", {
            attrs: {
              data: _vm.allProperties,
              "type-of-table": "organization-properties",
              "is-pagination-enabled": true,
              "is-loading": _vm.isLoading,
              "empty-state-action": {
                canAdd: _vm.canAddProperty,
                action: _vm.addNewProperty,
              },
            },
            on: { "update-table": _vm.getData },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("PropertiesRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "properties-list__modal" },
        [
          _c("TrackingCodeModal", {
            attrs: {
              "modal-data": _vm.selectedProperty || {},
              "web-sdk-id": _vm.selectedProperty.id,
              "display-sdk-snippet": _vm.displaySdkSnippet,
            },
          }),
        ],
        1
      ),
      _c("DeleteModal", {
        attrs: {
          title: _vm.$t("accountManagement.deleteProperty"),
          type: _vm.$t("property"),
        },
        on: { delete: _vm.deleteProperty },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }