<template>
  <section>
    <div class="table-list__header">
      <primary-create-button
        id="create-property"
        :name="$t('accountManagement.newProperty')"
        :is-loading="isLoading"
        :can-add="canAddProperty"
        :click-handler="addNewProperty"
      />
      <SearchInputField
        id="search-properties"
        :data="allProperties"
        debounce="400"
      />
    </div>
    <section class="table__wrapper">
      <CustomTable
        :data="allProperties"
        type-of-table="organization-properties"
        :is-pagination-enabled="true"
        :is-loading="isLoading"
        :empty-state-action="{
          canAdd: canAddProperty,
          action: addNewProperty,
        }"
        @update-table="getData"
      >
        <template #row-data="{ data }">
          <PropertiesRow :data="data" />
        </template>
      </CustomTable>
    </section>
    <div class="properties-list__modal">
      <TrackingCodeModal
        :modal-data="selectedProperty || {}"
        :web-sdk-id="selectedProperty.id"
        :display-sdk-snippet="displaySdkSnippet"
      />
    </div>
    <DeleteModal
      :title="$t('accountManagement.deleteProperty')"
      :type="$t('property')"
      @delete="deleteProperty"
    />
  </section>
</template>

<script>
import { properties } from '@/api';
import PropertiesRow from '@/components/table/table-data/PropertiesRow.vue';
import router from '@/router';
import store from '@/store';
import generateSdkSnippet from '@/utils/sdk-snippets';
import {
  CustomTable,
  DeleteModal,
  PrimaryCreateButton,
  SearchInputField,
  showToast,
} from '@nodus/utilities-front';
import { useClipboard } from '@vueuse/core';
import { VBModal, VBToggle } from 'bootstrap-vue';
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import TrackingCodeModal from './TrackingCodeModal.vue';

export default {
  name: 'Properties',
  components: {
    DeleteModal,

    // Bootstrap Components
    PrimaryCreateButton,

    SearchInputField,
    CustomTable,
    PropertiesRow,
    // Custom Components
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    TrackingCodeModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;
    const { copy } = useClipboard();

    const isLoading = ref(false);
    const canAddProperty = computed(
      () => store.getters['properties/getCanAdd']
    );
    const allProperties = computed(
      () => store.getters['properties/allProperties']
    );
    const propertyIdToDelete = computed(
      () => store.getters['properties/propertyToDelete']
    );
    const selectedProperty = computed(
      () => store.getters['properties/propertySelected']
    );

    const copySdk = (sdk, title) => {
      copy(sdk);
      showToast('success', title || 'Copied!');
    };

    let displaySdkSnippet = computed(() => {
      const { id } = selectedProperty.value;

      if (id !== '') return generateSdkSnippet(organizationId, id);

      return false;
    });

    const getData = async () => {
      isLoading.value = true;
      await store.dispatch('properties/getProperties', { id: organizationId });
      isLoading.value = false;
    };

    const deleteProperty = async () => {
      const id = propertyIdToDelete.value;
      await properties.delete(organizationId, id);
      getData();
    };

    const addNewProperty = () =>
      router.push({
        name: 'property-create',
        params: { organizationId },
      });

    onMounted(async () => {
      getData();
    });

    return {
      deleteProperty,
      displaySdkSnippet,
      allProperties,
      canAddProperty,
      organizationId,
      addNewProperty,
      isLoading,
      copySdk,
      getData,
      selectedProperty,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/views/properties';
</style>
