<script>
import { t } from '@/libs/i18n/utils';
import { DropdownActions, ReadMore } from '@nodus/utilities-front';
import { getCurrentInstance } from 'vue';

export default {
  name: 'TopicsRow',
  components: {
    DropdownActions,
    ReadMore,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { row } = props.data;

    const actionItems = [
      {
        id: 'edit-organization-user',
        icon: 'Edit',
        text: t('Edit'),
        disabled: !row.canEdit,
        modal: 'modal-lg-add-user',
        action: () =>
          vm.$store.commit('users/SET_ROW_TO_EDIT', {
            row,
            format: 'Organization',
          }),
      },
      {
        id: 'delete-organization-user',
        icon: 'Delete',
        text: t('Delete'),
        type: 'danger',
        disabled: !row.canDelete,
        modal: 'modal-small-delete',
        action: () =>
          vm.$store.commit('users/SET_ID_TO_DELETE', {
            id: row.userId,
            format: 'Organization',
          }),
      },
    ];

    return {
      actionItems,
    };
  },
};
</script>
<template>
  <div>
    <!-- Column: Name -->
    <span v-if="data.column.field === 'firstName'" class="text-nowrap">
      <span class="segment__avatar segment__avatar--padding">{{
        data.row.firstName.charAt(0).toUpperCase()
      }}</span>
      <span>{{ data.row.firstName }} {{ data.row.lastName }}</span>
    </span>

    <!-- Column: Email -->
    <span v-else-if="data.column.field === 'email'">
      <div class="flex-elem user-roles__actions">
        <ReadMore :text="data.row.email" />
      </div>
    </span>

    <!-- Column: Actions -->
    <span v-else-if="data.column.field === 'actions'">
      <DropdownActions :items="actionItems" />
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>
