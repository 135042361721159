<template>
  <div
    :class="
      typeOfGrid === 'invitations'
        ? 'user-roles__custom-search group-table d-flex align-right'
        : 'user-roles__custom-search group-table flex-elem'
    "
  >
    <div v-if="typeOfGrid !== 'invitations'">
      <div
        v-if="typeOfGrid === 'organization'"
        id="create-organization-user__wrapper"
      >
        <b-button
          id="create-organization-user"
          v-b-modal.modal-lg-add-user
          class="create__button"
          variant="gradient-primary"
          :disabled="!role || isLoading"
          @click="resetRowToEdit('Organization')"
        >
          <GjIcon name="Plus" class="mr-50" size="18" />
          <span class="align-middle">{{
            $t('accountManagement.userRoles.addUser')
          }}</span>
        </b-button>
        <b-tooltip
          v-if="!role"
          target="create-organization-user__wrapper"
          triggers="hover"
        >
          {{ $t('permissionMessage') }}
        </b-tooltip>
      </div>

      <div
        v-if="typeOfGrid === 'properties'"
        id="create-property-user__wrapper"
      >
        <b-button
          id="create-property-user"
          v-b-modal.modal-lg-add-user
          class="create__button"
          variant="gradient-primary"
          :disabled="!role || isLoading"
          @click="resetRowToEdit('Properties')"
        >
          <GjIcon name="Plus" class="mr-50" size="18" />
          <span class="align-middle">{{
            $t('accountManagement.userRoles.addUser')
          }}</span>
        </b-button>
        <b-tooltip
          v-if="!role"
          target="create-property-user__wrapper"
          triggers="hover"
        >
          {{ $t('permissionMessage') }}
        </b-tooltip>
      </div>

      <div v-if="typeOfGrid === 'apikeys'" id="create-apiKey__wrapper">
        <b-button
          id="create-apiKey"
          v-b-modal.modal-lg-add-user
          :disabled="!role || isLoading"
          class="create__button"
          variant="gradient-primary"
          @click="resetRowToEdit('APIKeys')"
        >
          <GjIcon name="Plus" class="mr-50" size="18" />
          <span class="align-middle">{{
            $t('accountManagement.userRoles.addApiKey')
          }}</span>
        </b-button>
        <b-tooltip
          v-if="!role"
          target="create-apiKey__wrapper"
          triggers="hover"
        >
          {{ $t('permissionMessage') }}
        </b-tooltip>
      </div>
    </div>
    <SearchInputField :id="`search-${typeOfGrid}`" :data="datasource" />
  </div>
</template>

<script>
import store from '@/store';
import { GjIcon, SearchInputField } from '@nodus/utilities-front';
import { BButton, BTooltip } from 'bootstrap-vue';

export default {
  name: 'TableHeader',
  components: {
    SearchInputField,
    GjIcon,

    // botstrap components
    BButton,
    BTooltip,
  },
  props: {
    typeOfGrid: {
      type: String,
      default: '',
    },
    role: {
      type: [Boolean],
    },
    datasource: {
      type: [Array, Object],
    },
    isLoading: Boolean,
  },
  setup() {
    const resetRowToEdit = (format) =>
      store.commit('users/SET_ROW_TO_EDIT', { row: null, format });

    return { resetRowToEdit };
  },
};
</script>
