<script>
import store from '@/store';
import {
  DateColumn,
  DropdownActions,
  GjIcon,
  ReadMore,
} from '@nodus/utilities-front';
import { getCurrentInstance } from 'vue';

export default {
  name: 'PropertiesRow',
  components: {
    ReadMore,
    DateColumn,
    DropdownActions,
    GjIcon,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;

    const dropdownActions = [
      {
        id: 'edit-property',
        icon: 'Edit',
        text: 'Edit',
        disabled: !props.data.row.canEdit,
        to: {
          name: 'property-edit',
          params: {
            organizationId,
            propertyId: props.data.row.id,
          },
        },
      },
      {
        id: 'delete-property',
        icon: 'Delete',
        text: 'Delete',
        type: 'danger',
        disabled: !props.data.row.canDelete,
        modal: 'modal-small-delete',
        action: () =>
          store.commit('properties/SET_ID_TO_DELETE', props.data.row.id),
      },
    ];

    return {
      organizationId,
      dropdownActions,
    };
  },
};
</script>
<template>
  <section>
    <!-- Column: Name -->
    <span v-if="data.column.field === 'name'" class="dark-gray-color">
      <div class="name__wrapper">
        <ReadMore :text="data.row.name" />
      </div>
    </span>

    <!-- Column: Description -->
    <span v-else-if="data.column.field === 'description'">
      <ReadMore :text="data.row.description" />
    </span>

    <!-- Column: Created Date -->
    <span v-else-if="data.column.field === 'insertDateTime'">
      <DateColumn :data="data"></DateColumn>
    </span>

    <!-- Column: Actions -->
    <span
      v-else-if="data.column.field === 'actions'"
      class="properties__actions"
    >
      <div
        id="tracking-code-property"
        v-b-modal.property-sdk
        class="property-sdk__button"
        @click="() => $store.commit('properties/SET_PROPERTY', data.row)"
      >
        <GjIcon name="Embed" size="24"></GjIcon>
      </div>
      <DropdownActions id="properties-actions" :items="dropdownActions" />
    </span>
  </section>
</template>

<style lang="scss">
.properties__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
</style>
