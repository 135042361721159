var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "email"
      ? _c("span", [
          _c(
            "div",
            { staticClass: "flex-elem user-roles__actions" },
            [_c("ReadMore", { attrs: { text: _vm.data.row.email } })],
            1
          ),
        ])
      : _vm.data.column.field === "properties"
      ? _c(
          "span",
          [_c("ReadMore", { attrs: { text: _vm.data.row.properties } })],
          1
        )
      : _vm.data.column.field === "status"
      ? _c("span", [
          _c("div", { staticClass: "flex-elem user-roles__actions" }, [
            _c("span", [_vm._v(_vm._s(_vm.data.row.status))]),
          ]),
        ])
      : _vm.data.column.field === "actions"
      ? _c(
          "span",
          [_c("DropdownActions", { attrs: { items: _vm.actionItems } })],
          1
        )
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }