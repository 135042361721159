<script>
import { t } from '@/libs/i18n/utils';
import {
  DateColumn,
  DropdownActions,
  ReadMore,
  formatDate,
  // utility functions
  isDateExpired,
} from '@nodus/utilities-front';
import { getCurrentInstance } from 'vue';

export default {
  name: 'TopicsRow',
  emits: ['trigger-activation'],
  components: {
    DropdownActions,
    ReadMore,
    DateColumn,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { row } = props.data;

    const actionItems = [
      {
        id: 'regenerate-apiKey',
        icon: 'Refresh',
        text: t('accountManagement.userRoles.regenerate'),
        disabled: !row.canEdit || isDateExpired(row.expiryDate),
        action: () => vm.$emit('trigger-activation', 'apikeys', row.id),
      },
      {
        id: 'revoke-apiKey',
        icon: 'Delete',
        text: t('accountManagement.userRoles.revoke'),
        type: 'danger',
        modal: 'modal-small-delete',
        disabled: !row.canEdit,
        action: () =>
          vm.$store.commit('users/SET_ID_TO_DELETE', {
            id: row.id,
            format: 'APIKeys',
          }),
      },
    ];

    const roleCheck = (role) => {
      const roles = {
        2: 'Admin',
        3: 'Analyst',
      };
      return roles[role];
    };

    return {
      actionItems,
      isDateExpired,
      roleCheck,
      formatDate,
    };
  },
};
</script>
<template>
  <div>
    <!-- Column: API KEY Name -->
    <span v-if="data.column.field === 'name'">
      <ReadMore :text="data.row.name" />
    </span>

    <!-- Column: API Key Role -->
    <span v-else-if="data.column.field === 'apiKeyRole'">
      {{ roleCheck(data.row.apiKeyRole) }}
    </span>

    <!-- Column: Date -->
    <span
      v-else-if="data.column.field === 'insertDateTime'"
      class="flex-elem apikeys__actions"
    >
      <DateColumn :data="data" />
    </span>

    <!-- Column: Expiration Date -->
    <span
      v-else-if="data.column.field === 'expiryDate'"
      class="flex-elem apikeys__actions"
    >
      <div
        :class="{
          'api-keys__expired--text': isDateExpired(data.row.expiryDate),
        }"
      >
        {{
          isDateExpired(data.row.expiryDate)
            ? 'Expired'
            : formatDate(data.row.expiryDate)
        }}
      </div>
    </span>

    <!-- Column: Actions -->
    <span v-else-if="data.column.field === 'actions'">
      <DropdownActions :items="actionItems" />
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>
