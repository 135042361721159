export default [
  {
    path: '/bisko/:organizationId/organization/properties',
    name: 'properties',
    component: () => import('@/views/properties/Properties.vue'),
    meta: {
      navActiveLink: 'properties',
      pageTitle: 'Properties',
      breadcrumb: [
        {
          text: 'Organization'
        },
        {
          text: 'Properties',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/organization/properties/create',
    name: 'property-create',
    component: () => import('@/views/properties/PropertyCreate.vue'),
    meta: {
      navActiveLink: 'properties',
      pageTitle: 'Add New Property',
      breadcrumb: [
        {
          text: 'Organization'
        },
        {
          text: 'Properties',
          to: '/properties'
        },
        {
          text: 'Create Property',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/organization/properties/:propertyId/edit',
    name: 'property-edit',
    component: () => import('@/views/properties/PropertyCreate.vue'),
    meta: {
      navActiveLink: 'properties',
      pageTitle: 'Edit Property',
      breadcrumb: [
        {
          text: 'Organization'
        },
        {
          text: 'Properties',
          to: '/properties'
        },
        {
          text: 'Edit Property',
          active: true
        }
      ]
    }
  }
]
