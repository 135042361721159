<template>
  <div class="user-roles-tables__modal">
    <b-modal
      id="modal-lg-add-user"
      ref="addUserModal"
      modal-class="modal__body__text--left user-roles__modal"
      :ok-disabled="isLoading"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      :title="title"
      no-enforce-focus
      no-close-on-backdrop
      no-close-on-esc
      @cancel="onModalClose"
      @close="onModalClose"
      @ok="triggerSave"
    >
      <template #modal-ok>
        <b-spinner v-if="isLoading" small class="mr-50" />
        <span>{{ !isEditing ? $t('Add') : $t('Save') }}</span>
      </template>
      <div class="user-roles-tables__modal-content">
        <div v-if="typeOfGrid !== 'apikeys'" class="mb-50 input__wrapper">
          <label>{{ $t('Organization') }}</label>
          <b-form-input
            id="organization-name"
            v-model="organizationName"
            :placeholder="$t('Organization')"
            class="form__input form__input--dark"
            type="text"
            readonly
          />
        </div>

        <div
          v-if="typeOfGrid === 'properties'"
          class="user-roles-tables__modal-content-selectproperty input__wrapper mb-50"
        >
          <label class="mr-1">{{ $t('property') }} *</label>
          <b-form-input
            v-if="isEditing"
            id="property-selected"
            v-model="propertyIds.value"
            :placeholder="$t('property')"
            class="form__input form__input--dark"
            type="text"
            readonly
          />
          <MultipleProperties
            v-else
            id="user-properties"
            :options="allProperties"
            label="title"
            :tags-to-show="3"
            no-debounce
            :classes="['multiple-properties--three']"
            :all-selected-classes="['multiple-properties--all']"
            :invalid-input="propertyIds.errors.length > 0"
            @update-data="updateProperties"
          />
          <div
            v-for="(error, errIndex) in propertyIds.errors"
            :key="`err-${errIndex}`"
            class="error__info-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>

        <div v-if="typeOfGrid !== 'apikeys'" class="mb-50 input__wrapper">
          <label>{{ $t('EmailAddress') }} *</label>
          <b-form-input
            id="email"
            v-model.trim="email.value"
            :placeholder="$t('emailExample')"
            class="form__input"
            :class="{
              'form__input--dark': isEditing,
              'form__input--invalid': email.errors.length > 0
            }"
            type="text"
            :readonly="isEditing"
            @input="handleFormInput(null, [email])"
          />
          <div
            v-for="(error, errIndex) in email.errors"
            :key="errIndex"
            class="error__info-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>

        <div v-if="typeOfGrid === 'apikeys'">
          <div class="mb-50 input__wrapper">
            <label>{{ $t('Name') }} *</label>
            <b-form-input
              id="api-key"
              v-model.trim="name.value"
              :placeholder="$t('enterApiKey')"
              class="form__input"
              :class="{ 'form__input--invalid': name.errors.length > 0 }"
              @input="handleFormInput(null, [name])"
            />
            <div
              v-for="(error, errIndex) in name.errors"
              :key="`err-${errIndex}`"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </div>
          <div class="user-roles-tables__modal-content-expire-date mb-2">
            <label class="user-roles-tables__modal-content-date-label">{{
              $t('accountManagement.userRoles.expirationDate')
            }}</label>
            <span id="tooltip-date">
              <GjIcon
                name="Info"
                size="20"
                class="user-roles__info-icon"
              ></GjIcon>
            </span>
            <b-tooltip
              target="tooltip-date"
              triggers="hover"
              placement="bottom"
            >
              {{ $t('accountManagement.userRoles.expirationDateInfo') }}
            </b-tooltip>
            <b-form-datepicker
              id="expiry-date"
              v-model="expiryDate.value"
              calendar-width="100%"
              :min="currentDate"
              class="form__input"
              :class="{
                'form__input--invalid': expiryDate.errors.length > 0
              }"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              @input="handleFormInput(null, [expiryDate])"
            ></b-form-datepicker>
            <div
              v-for="(error, errIndex) in expiryDate.errors"
              :key="`err-${errIndex}`"
              class="error__info-message"
            >
              <span>{{ error }}</span>
            </div>
          </div>
        </div>

        <div class="user-roles-tables__modal-content-roles">
          <label class="mr-1">{{ $t('Role') }} *</label>
          <div v-for="(option, index) in options" :key="index">
            <div
              v-if="typeOfGrid !== 'apikeys' || option.value !== 'owner'"
              class="role-options__container"
            >
              <b-form-radio
                :id="`${option.text}-role`"
                v-model="role.value"
                class="role-options"
                :value="option.value"
                :disabled="option.disabled"
                @input="handleFormInput(null, [role])"
              >
                {{ option.text }}
              </b-form-radio>
              <span :id="'option-' + typeOfGrid + option.text">
                <GjIcon
                  name="Info"
                  class="mr-50 user-roles__info-icon"
                  size="20"
                />
              </span>
              <b-tooltip
                :target="'option-' + typeOfGrid + option.text"
                triggers="hover"
                placement="bottom"
                custom-class="tooltip__role-information"
              >
                {{ showRoleInformation(typeOfGrid + option.text) }}.
              </b-tooltip>
            </div>
          </div>
          <div
            v-for="(error, errIndex) in role.errors"
            :key="`err-${errIndex}`"
            class="error__info-message"
          >
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { t } from '@/libs/i18n/utils'
import {
  clearErrorsMessages,
  handleSave
} from '@/views/users/tableUtilFunctions'
import {
  GjIcon,
  MultipleProperties,
  handleFormInput
} from '@nodus/utilities-front'
import { BFormInput, BModal, BSpinner } from 'bootstrap-vue'
import { computed, getCurrentInstance, reactive, ref, toRefs, watch } from 'vue'

export default {
  name: 'AddUsersModal',
  components: {
    // utility components
    GjIcon,
    MultipleProperties,

    // bootstrap components
    BModal,
    BFormInput,
    BSpinner
  },
  props: {
    typeOfGrid: {
      type: String,
      required: true
    },
    tabData: {
      type: Object,
      default: () => {}
    },
    allProperties: {
      type: Array,
      default: () => []
    },
    organizationName: String
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const addUserModal = ref()
    const isLoading = ref(false)

    const addTitle = {
      organization: t('accountManagement.userRoles.addUserOrganization'),
      properties: t('accountManagement.userRoles.addUserOrganization'),
      invitations: '',
      apikeys: t('accountManagement.userRoles.addApiKey')
    }
    const editTitle = {
      organization: t('accountManagement.userRoles.editUserPermission'),
      properties: t('accountManagement.userRoles.editUserPermission'),
      invitations: '',
      apikeys: ''
    }

    const options = [
      { text: 'Owner', value: 'owner', disabled: true },
      { text: 'Admin', value: 'admin', disabled: false },
      { text: 'Analyst', value: 'analyst', disabled: false }
    ]

    const showRoleInformation = (role) => {
      const information = {
        organizationOwner: t(
          'accountManagement.userRoles.organizationOwnerInformaton'
        ),
        organizationAdmin: t(
          'accountManagement.userRoles.organizationAdminInformation'
        ),
        organizationAnalyst: t(
          'accountManagement.userRoles.organizationAnalystInformation'
        ),
        propertiesOwner: t(
          'accountManagement.userRoles.propertyOwnerInformation'
        ),
        propertiesAdmin: t(
          'accountManagement.userRoles.propertyAdminInformation'
        ),
        propertiesAnalyst: t(
          'accountManagement.userRoles.propertyAnalystInformation'
        ),
        apikeysAdmin: t('accountManagement.userRoles.apiKeysAdminInformation'),
        apikeysAnalyst: t(
          'accountManagement.userRoles.apiKeysAnalystInformation'
        )
      }
      return information[role]
    }

    const currentDate = new Date()

    const state = reactive({
      name: {
        value: '',
        errors: []
      },
      email: {
        value: '',
        errors: []
      },
      role: {
        value: '',
        errors: []
      },
      expiryDate: {
        value: '',
        errors: []
      },
      propertyIds: {
        value: [],
        errors: []
      },
      organizationCode: vm.$route.params?.organizationId,
      isEditing: false
    })

    watch(props, ({ tabData, typeOfGrid }) => {
      state.isEditing = tabData.rowToEdit !== null

      if (tabData.rowToEdit !== null && typeOfGrid !== 'apikeys') {
        state.email.value = tabData.rowToEdit?.email || ''
        state.role.value = tabData.rowToEdit?.userRole.toLowerCase() || ''
        state.propertyIds.value = tabData.rowToEdit?.property || []
      }
    })

    const title = computed(() =>
      state.isEditing ? editTitle[props.typeOfGrid] : addTitle[props.typeOfGrid]
    )

    const updateProperties = (selected) => {
      state.propertyIds.value = selected
      state.propertyIds.errors = []
    }

    const onModalClose = () => {
      state.isEditing = false
      state.role.value = ''
      state.name.value = ''
      state.email.value = ''
      state.expiryDate.value = ''
      state.propertyIds.value = []
      props.tabData.rowToEdit = null
      clearErrorsMessages(state)
    }

    const triggerSave = async (modalEvent) => {
      isLoading.value = true
      modalEvent.preventDefault()
      clearErrorsMessages(state)

      const response = await handleSave(state, props, vm.$store)
      if (response) {
        addUserModal.value.hide()
        onModalClose()
        if (props.typeOfGrid === 'apikeys') vm.$bvModal.show('apiKeyModal')
      }
      isLoading.value = false
    }

    return {
      ...toRefs(state),
      addUserModal,
      isLoading,
      title,
      updateProperties,
      handleFormInput,
      currentDate,
      options,
      triggerSave,
      onModalClose,
      showRoleInformation
    }
  }
}
</script>
