<script>
import APIKeyModal from '@/components/APIKeyModal.vue'
import AddUsersModal from '@/components/AddUsersModal.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import {
  APIKeysRow,
  InvitationsRow,
  OrganizationUsersRow,
  PropertyUsersRow
} from '@/components/table/table-data'
import { t } from '@/libs/i18n/utils'
import store from '@/store'
import { CustomTable, DeleteModal } from '@nodus/utilities-front'
import { BTab, BTabs } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import { handleDelete, resendActivation } from './tableUtilFunctions'

export default {
  components: {
    // Bootstrap Components
    BTabs,
    BTab,

    CustomTable,
    TableHeader,

    // table rows
    OrganizationUsersRow,
    PropertyUsersRow,
    InvitationsRow,
    APIKeysRow,

    // modal components
    AddUsersModal,
    APIKeyModal,
    DeleteModal
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(true)
    const activeTab = ref(0)

    const allTabs = computed(() => store.getters['users/allTabs'])
    const tableFormat = computed(() => store.getters['users/tableFormat'])
    const orgName = computed(() => store.getters['users/orgName'])

    onMounted(async () => {
      await store.dispatch('users/getTabs', { id: organizationId })
      isLoading.value = false
      if (allTabs.value?.Organization) {
        store
          .dispatch('users/getOrganizationUsers', { id: organizationId })
          .then(() => {
            allTabs.value.Organization.isLoading = false
          })
      }

      if (allTabs.value?.Properties) {
        store.dispatch('users/getPropertyUsers', { id: organizationId })
        store
          .dispatch('properties/getByUserRole', { id: organizationId })
          .then(() => {
            allTabs.value.Properties.isLoading = false
          })
      }

      if (allTabs.value?.Invitations) {
        store
          .dispatch('users/getInvitationUsers', { id: organizationId })
          .then(() => {
            allTabs.value.Invitations.isLoading = false
          })
      }

      if (allTabs.value?.APIKeys) {
        store.dispatch('users/getAPIKeys', { id: organizationId }).then(() => {
          allTabs.value.APIKeys.isLoading = false
        })
      }
    })

    const getData = async (typeOfGrid) => {
      switch (typeOfGrid.toLowerCase()) {
        case 'organization':
          allTabs.value.Organization.isLoading = true
          await store.dispatch('users/getOrganizationUsers', {
            id: organizationId
          })
          allTabs.value.Organization.isLoading = false
          break
        case 'properties':
          allTabs.value.Properties.isLoading = true
          await store.dispatch('users/getPropertyUsers', {
            id: organizationId
          })
          allTabs.value.Properties.isLoading = false
          break
        case 'invitations':
          allTabs.value.Invitations.isLoading = true
          await store.dispatch('users/getInvitationUsers', {
            id: organizationId
          })
          allTabs.value.Invitations.isLoading = false
          break
        case 'apikeys':
          allTabs.value.APIKeys.isLoading = true
          await store.dispatch('users/getAPIKeys', { id: organizationId })
          allTabs.value.APIKeys.isLoading = false
          break
        default:
          break
      }
    }

    const allProperties = computed(() =>
      store.getters['properties/allUserProperties'].reduce((acc, curr) => {
        acc.push({ id: curr.id, title: curr.name })

        return acc
      }, [])
    )

    const activeTabName = computed(
      () => Object.keys(allTabs.value)[activeTab.value]
    )

    const formatModalTitle = (gridType) => {
      const gridTypes = {
        organization: t(
          'accountManagement.userRoles.deleteUserOrganizationTitle'
        ),
        properties: t('accountManagement.userRoles.deletePropertyTitle'),
        invitations: t('accountManagement.userRoles.deleteInvitationTitle'),
        apikeys: t('accountManagement.userRoles.revokeApiKeyTitle')
      }
      return gridTypes[gridType.toLowerCase()]
    }

    const formatDeleteContent = (gridType) => {
      const gridTypes = {
        organization: t('accountManagement.userRoles.deleteOrganization'),
        properties: t('accountManagement.userRoles.deleteProperty'),
        invitations: t('accountManagement.userRoles.deleteInvitation'),
        apikeys: t('accountManagement.userRoles.deleteApiKey')
      }
      return gridTypes[gridType.toLowerCase()]
    }

    const handleLastRowDelete = (typeOfGrid) => {
      const { totalRecords, page, perPage, rows } =
        tableFormat.value[typeOfGrid]
      const lastPage = Math.ceil(totalRecords / perPage)
      if (page === lastPage && page !== 1 && rows.length === 1) {
        store.commit('users/SET_USERS_TABLE_PAGE', {
          page: page - 1,
          format: typeOfGrid
        })
      }
    }

    const triggerDelete = async (typeOfGrid) => {
      handleLastRowDelete(typeOfGrid)
      await handleDelete(allTabs.value[typeOfGrid], organizationId)

      getData(typeOfGrid)
    }

    const triggerActivation = async (typeOfGrid, id) => {
      const validRequest = await resendActivation(
        typeOfGrid,
        id,
        organizationId,
        store
      )
      if (validRequest && typeOfGrid === 'apikeys')
        vm.$bvModal.show('apiKeyModal')
      if (validRequest) getData(typeOfGrid)
    }

    return {
      allTabs,
      orgName,
      tableFormat,
      allProperties,
      organizationId,
      getData,
      isLoading,
      formatModalTitle,
      formatDeleteContent,
      triggerDelete,
      triggerActivation,
      activeTab,
      activeTabName
    }
  }
}
</script>

<template>
  <section class="users__container">
    <b-tabs v-model="activeTab" align="left">
      <b-tab
        v-for="tab in allTabs"
        :key="tab.entityName"
        :id="tab.entityName"
        :title="tab.entityName === 'APIKeys' ? 'API Keys' : tab.entityName"
      >
        <section class="user-roles audience__section">
          <!-- header section -->
          <TableHeader
            :datasource="tableFormat[tab.entityName]"
            :type-of-grid="tab.entityName.toLowerCase()"
            :role="tab.canAddUser"
            :is-loading="tab.isLoading"
          />
          <div class="table__wrapper">
            <CustomTable
              :data="tableFormat[tab.entityName]"
              :type-of-table="tab.entityName.toLowerCase()"
              is-pagination-enabled
              :is-loading="tab.isLoading"
              @update-table="getData(tab.entityName)"
            >
              <template #row-data="{ data }">
                <OrganizationUsersRow
                  v-if="tab.entityName == 'Organization'"
                  :key="data.row.id"
                  :data="data"
                />
                <PropertyUsersRow
                  v-else-if="tab.entityName == 'Properties'"
                  :key="data.row.id"
                  :data="data"
                />
                <InvitationsRow
                  v-else-if="tab.entityName == 'Invitations'"
                  :key="data.row.invitationCode"
                  :data="data"
                  @trigger-activation="triggerActivation"
                />
                <APIKeysRow
                  v-else
                  :key="data.row.id"
                  :data="data"
                  @trigger-activation="triggerActivation"
                />
              </template>
            </CustomTable>
          </div>
        </section>
      </b-tab>
    </b-tabs>
    <APIKeyModal v-if="activeTabName" :token="allTabs.APIKeys.token" />
    <AddUsersModal
      v-if="activeTabName"
      :type-of-grid="activeTabName.toLowerCase()"
      :organization-name="orgName"
      :all-properties="allProperties"
      :tab-data="allTabs[activeTabName]"
    />
    <DeleteModal
      v-if="activeTabName"
      :title="formatModalTitle(activeTabName)"
      :type="activeTabName.toLowerCase()"
      :ok-title="
        activeTabName === 'APIKeys'
          ? $t('accountManagement.userRoles.revoke')
          : $t('Delete')
      "
      :custom-content="formatDeleteContent(activeTabName)"
      @delete="triggerDelete(activeTabName)"
    />
  </section>
</template>

<style lang="scss">
@import '@/assets/scss/views/users';
@import '@/assets/scss/views/users/table-grid';
</style>
