var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "name"
      ? _c("span", [_c("ReadMore", { attrs: { text: _vm.data.row.name } })], 1)
      : _vm.data.column.field === "apiKeyRole"
      ? _c("span", [
          _vm._v(" " + _vm._s(_vm.roleCheck(_vm.data.row.apiKeyRole)) + " "),
        ])
      : _vm.data.column.field === "insertDateTime"
      ? _c(
          "span",
          { staticClass: "flex-elem apikeys__actions" },
          [_c("DateColumn", { attrs: { data: _vm.data } })],
          1
        )
      : _vm.data.column.field === "expiryDate"
      ? _c("span", { staticClass: "flex-elem apikeys__actions" }, [
          _c(
            "div",
            {
              class: {
                "api-keys__expired--text": _vm.isDateExpired(
                  _vm.data.row.expiryDate
                ),
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isDateExpired(_vm.data.row.expiryDate)
                      ? "Expired"
                      : _vm.formatDate(_vm.data.row.expiryDate)
                  ) +
                  " "
              ),
            ]
          ),
        ])
      : _vm.data.column.field === "actions"
      ? _c(
          "span",
          [_c("DropdownActions", { attrs: { items: _vm.actionItems } })],
          1
        )
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }