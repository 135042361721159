import axiosIns from '@/libs/axios'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    tabs: [],
    name: '',
    tableFormat: {
      Organization: {
        columns: [
          {
            label: 'Name',
            field: 'firstName',
            thClass: 'user-name-col',
            width: '33%'
          },
          {
            label: 'Role',
            field: 'userRole',
            width: '20%'
          },
          {
            label: 'Email',
            field: 'email',
            width: '40%'
          },
          {
            label: 'Actions',
            field: 'actions',
            width: '7%',
            thClass: 'not-sortable-headers',
            sortable: false
          }
        ],
        rows: [],
        searchTerm: '',
        totalRecords: 0,
        page: 1,
        perPage: 10,
        orderField: '',
        orderType: ''
      },
      Properties: {
        columns: [
          {
            label: 'Name',
            field: 'firstName',
            width: '22%',
            thClass: 'user-name-col'
          },
          {
            label: 'Role',
            field: 'userRole',
            width: '17%'
          },
          {
            label: 'Property',
            field: 'property',
            width: '22%'
          },
          {
            label: 'Email',
            field: 'email',
            width: '32%'
          },
          {
            label: 'Actions',
            field: 'actions',
            width: '7%',
            thClass: 'not-sortable-headers',
            sortable: false
          }
        ],
        rows: [],
        searchTerm: '',
        totalRecords: 0,
        page: 1,
        perPage: 10,
        orderField: '',
        orderType: ''
      },
      Invitations: {
        columns: [
          {
            label: 'Email',
            field: 'email',
            width: '33%'
          },
          {
            label: 'Role',
            field: 'userRole',
            width: '20%'
          },
          {
            label: 'Property',
            field: 'properties',
            width: '20%',
            sortable: false,
            thClass: 'not-sortable-headers'
          },
          {
            label: 'Status',
            field: 'status',
            width: '20%'
          },
          {
            label: 'Actions',
            field: 'actions',
            width: '7%',
            thClass: 'not-sortable-headers',
            sortable: false
          }
        ],
        rows: [],
        searchTerm: '',
        totalRecords: 0,
        page: 1,
        perPage: 10,
        orderField: '',
        orderType: ''
      },
      APIKeys: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            width: '25%',
            thClass: 'name-column'
          },
          {
            label: 'Role',
            field: 'apiKeyRole',
            width: '12%'
          },
          {
            label: 'Date',
            field: 'insertDateTime',
            width: '33%'
          },
          {
            label: 'Expiration Date',
            field: 'expiryDate',
            width: '23%'
          },
          {
            label: 'Actions',
            field: 'actions',
            width: '7%',
            thClass: 'not-sortable-headers',
            sortable: false
          }
        ],
        rows: [],
        searchTerm: '',
        totalRecords: 0,
        page: 1,
        perPage: 10,
        orderField: '',
        orderType: ''
      }
    }
  },
  getters: {
    allTabs: (state) => state.tabs,
    tableFormat: (state) => state.tableFormat,
    orgName: (state) => state.name
  },
  actions: {
    async getTabs({ commit }, { id }) {
      const response = await axiosIns.get(`/api/v1/${id}/users/view`)

      commit(
        'SET_ORGANIZATION_NAME',
        destructJSONObject(response, 'organizationName')
      )
      commit('SET_TABS', destructJSONObject(response, 'userEntities'))
      return destructJSONObject(response, 'userEntities')
    },
    async getOrganizationUsers({ commit, state }, { id }) {
      const orgTable = state.tableFormat.Organization
      const queryParamString = formatRequestQuery(
        orgTable.searchTerm,
        orgTable.page,
        orgTable.perPage,
        orgTable.orderField,
        orgTable.orderType
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/users?${queryParamString}`
      )

      commit('SET_ORGANIZATION_USERS', destructJSONObject(response, 'users'))
      commit('SET_TOTAL_TABLE_RECORDS', {
        format: 'Organization',
        totalRecords: destructJSONObject(response, 'totalCount')
      })
    },
    async getPropertyUsers({ commit, state }, { id }) {
      const propTable = state.tableFormat.Properties
      const queryParamString = formatRequestQuery(
        propTable.searchTerm,
        propTable.page,
        propTable.perPage,
        propTable.orderField,
        propTable.orderType
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/properties/users?${queryParamString}`
      )

      commit('SET_PROPERTY_USERS', destructJSONObject(response, 'users'))
      commit('SET_TOTAL_TABLE_RECORDS', {
        format: 'Properties',
        totalRecords: destructJSONObject(response, 'totalCount')
      })
    },
    async getInvitationUsers({ commit, state }, { id }) {
      const invTable = state.tableFormat.Invitations
      const queryParamString = formatRequestQuery(
        invTable.searchTerm,
        invTable.page,
        invTable.perPage,
        invTable.orderField,
        invTable.orderType
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/users/invitations?${queryParamString}`
      )

      commit('SET_TOTAL_TABLE_RECORDS', {
        format: 'Invitations',
        totalRecords: destructJSONObject(response, 'totalCount')
      })
      commit(
        'SET_INVITATIONS_USERS',
        destructJSONObject(response, 'userInvitations')
      )
    },
    async getAPIKeys({ commit, state }, { id }) {
      const apiKeysTable = state.tableFormat.APIKeys
      const queryParamString = formatRequestQuery(
        apiKeysTable.searchTerm,
        apiKeysTable.page,
        apiKeysTable.perPage,
        apiKeysTable.orderField,
        apiKeysTable.orderType
      )
      const response = await axiosIns.get(
        `/api/v2/${id}/apikeys?${queryParamString}`
      )

      commit('SET_APIKEYS', destructJSONObject(response, 'apiKeys'))
      commit('SET_TOTAL_TABLE_RECORDS', {
        format: 'APIKeys',
        totalRecords: destructJSONObject(response, 'totalCount')
      })
    }
  },
  mutations: {
    SET_TABS(state, tabs) {
      Object.keys(tabs).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        tabs[key].rowToEdit = null
        tabs[key].IDtoDelete = -1
        tabs[key].isLoading = true
        if (key === 'APIKeys') tabs[key].token = ''
      })
      state.tabs = tabs
    },
    SET_ORGANIZATION_USERS(state, users) {
      state.tableFormat.Organization.rows = users
    },
    SET_PROPERTY_USERS(state, users) {
      state.tableFormat.Properties.rows = users
    },
    SET_INVITATIONS_USERS(state, users) {
      const modifiedUsers = users.reduce((acc, curr) => {
        // eslint-disable-next-line no-param-reassign
        curr.properties = curr.properties.join(', ')
        acc.push(curr)
        return acc
      }, [])

      state.tableFormat.Invitations.rows = modifiedUsers
    },
    SET_APIKEYS(state, apiKeys) {
      state.tableFormat.APIKeys.rows = apiKeys
    },
    SET_ORGANIZATION_NAME(state, name) {
      state.name = name
    },
    SET_TOTAL_TABLE_RECORDS(state, { format, totalRecords }) {
      state.tableFormat[format].totalRecords = totalRecords
    },
    SET_USERS_TABLE_PAGE(state, { format, page }) {
      state.tableFormat[format].page = page
    },
    SET_APIKEY_TOKEN(state, token) {
      state.tabs.APIKeys.token = token
    },
    SET_ID_TO_DELETE(state, { id, format, property = null }) {
      state.tabs[format].IDtoDelete = id
      if (property) state.tabs.Properties.property = property
    },
    SET_ROW_TO_EDIT(state, { row, format }) {
      state.tabs[format].rowToEdit = row
    }
  }
}
