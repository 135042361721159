var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "users__container" },
    [
      _c(
        "b-tabs",
        {
          attrs: { align: "left" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.allTabs, function (tab) {
          return _c(
            "b-tab",
            {
              key: tab.entityName,
              attrs: {
                id: tab.entityName,
                title:
                  tab.entityName === "APIKeys" ? "API Keys" : tab.entityName,
              },
            },
            [
              _c(
                "section",
                { staticClass: "user-roles audience__section" },
                [
                  _c("TableHeader", {
                    attrs: {
                      datasource: _vm.tableFormat[tab.entityName],
                      "type-of-grid": tab.entityName.toLowerCase(),
                      role: tab.canAddUser,
                      "is-loading": tab.isLoading,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "table__wrapper" },
                    [
                      _c("CustomTable", {
                        attrs: {
                          data: _vm.tableFormat[tab.entityName],
                          "type-of-table": tab.entityName.toLowerCase(),
                          "is-pagination-enabled": "",
                          "is-loading": tab.isLoading,
                        },
                        on: {
                          "update-table": function ($event) {
                            return _vm.getData(tab.entityName)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row-data",
                              fn: function ({ data }) {
                                return [
                                  tab.entityName == "Organization"
                                    ? _c("OrganizationUsersRow", {
                                        key: data.row.id,
                                        attrs: { data: data },
                                      })
                                    : tab.entityName == "Properties"
                                    ? _c("PropertyUsersRow", {
                                        key: data.row.id,
                                        attrs: { data: data },
                                      })
                                    : tab.entityName == "Invitations"
                                    ? _c("InvitationsRow", {
                                        key: data.row.invitationCode,
                                        attrs: { data: data },
                                        on: {
                                          "trigger-activation":
                                            _vm.triggerActivation,
                                        },
                                      })
                                    : _c("APIKeysRow", {
                                        key: data.row.id,
                                        attrs: { data: data },
                                        on: {
                                          "trigger-activation":
                                            _vm.triggerActivation,
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm.activeTabName
        ? _c("APIKeyModal", { attrs: { token: _vm.allTabs.APIKeys.token } })
        : _vm._e(),
      _vm.activeTabName
        ? _c("AddUsersModal", {
            attrs: {
              "type-of-grid": _vm.activeTabName.toLowerCase(),
              "organization-name": _vm.orgName,
              "all-properties": _vm.allProperties,
              "tab-data": _vm.allTabs[_vm.activeTabName],
            },
          })
        : _vm._e(),
      _vm.activeTabName
        ? _c("DeleteModal", {
            attrs: {
              title: _vm.formatModalTitle(_vm.activeTabName),
              type: _vm.activeTabName.toLowerCase(),
              "ok-title":
                _vm.activeTabName === "APIKeys"
                  ? _vm.$t("accountManagement.userRoles.revoke")
                  : _vm.$t("Delete"),
              "custom-content": _vm.formatDeleteContent(_vm.activeTabName),
            },
            on: {
              delete: function ($event) {
                return _vm.triggerDelete(_vm.activeTabName)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }