<template>
  <b-modal
    id="property-sdk"
    modal-class="modal__body__text--left tracking-code__header"
    ok-only
    ok-title="Close"
    ok-variant="outline-secondary"
    centered
    size="lg"
    :title="`${$t('accountManagement.trackingCode')} \n ${modalData.name}`"
    no-enforce-focus
    no-close-on-backdrop
  >
    <div class="properties-list__modal-content">
      <div class="properties-list__modal-content-id">
        <span>{{ $t("id").toUpperCase() }}</span>
        <b-form-input
          class="form__input--dark"
          :value="webSdkId"
          readonly
          @click="doCopy(webSdkId, 'Property id')"
          id="copy-property-id"
        />
        <GjIcon name="Copy" class="mr-50" size="22" />
      </div>
      <div class="properties-list__modal-content-code">
        <b-tabs align="left">
          <b-tab id="web" active :title="$t('accountManagement.Web')">
            <div class="documentation__info-text mb-1">
              <span
                >{{ $t("accountManagement.webSdkDocumentationLink") }}
                <a
                  :href="`${whitelabel.documentationUrl}/websdk`"
                  target="_blank"
                  >{{ $t("Documentation").toLowerCase() }}</a
                >.</span
              >
            </div>
            <span class="properties-list__modal-content-code-trackingcode">{{
              $t("accountManagement.trackingCode")
            }}</span>
            <pre>
                  <code class="properties__tracking-code" v-text='displaySdkSnippet.WEB' />
                </pre>
            <div
              class="properties-list__modal-content-code-copycode"
              @click="doCopy(displaySdkSnippet.WEB, 'Bisko Web SDK')"
              id="copy-web-code"
            >
              <span>{{ $t("accountManagement.copyToClipboard") }}</span>
              <GjIcon name="Copy" class="mr-50" size="22" />
            </div>
          </b-tab>
          <b-tab id="ios" :title="$t('accountManagement.iOS')">
            <div class="documentation__info-text mb-50 ml-50">
              <span
                >{{ $t("accountManagement.iosSdkDocumentationLink") }}
                <a
                 :href="`${whitelabel.documentationUrl}/mobilesdk#bisko-ios-sdk`"
                  target="_blank"
                  >{{ $t("Documentation").toLowerCase() }}</a
                >.</span
              >
            </div>
            <div
              v-for="data in Object.keys(displaySdkSnippet.IOS)"
              :key="data"
              class="mobile__wrapper"
            >
              <div
                v-b-toggle="'accordion-' + data"
                class="presets-container__expandable-heading mobile__wrapper-heading"
              >
                {{ data }}
              </div>
              <b-collapse :id="`accordion-${data}`">
                <div>
                  <span
                    class="properties-list__modal-content-code-trackingcode"
                  >
                    {{ $t("accountManagement.trackingCode") }}
                  </span>
                  <pre>
                        <code class="properties__tracking-code" v-text='displaySdkSnippet.IOS[data]' />
                      </pre>
                  <div
                    class="properties-list__modal-content-code-copycode"
                    @click="
                      doCopy(
                        displaySdkSnippet.IOS[data],
                        `Bisko ${data} IOS SDK`
                      )
                    "
                    :id="`copy-${data}-code`"
                  >
                    <span>{{ $t("accountManagement.copyToClipboard") }}</span>
                    <GjIcon name="Copy" class="mr-50" size="22" />
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-tab>
          <b-tab id="android" :title="$t('accountManagement.Android')">
            <div class="documentation__info-text mb-50 ml-50">
              <span
                >{{ $t("accountManagement.androidSdkDocumentationLink") }}
                <a
                  :href="`${whitelabel.documentationUrl}/mobilesdk#bisko-android-sdk`"
                  target="_blank"
                  >{{ $t("Documentation").toLowerCase() }}</a
                >.</span
              >
            </div>
            <div
              v-for="data in Object.keys(displaySdkSnippet.ANDROID)"
              :key="data"
              class="mobile__wrapper"
            >
              <div
                v-b-toggle="'accordion-' + data"
                class="presets-container__expandable-heading mobile__wrapper-heading"
              >
                {{ data }}
              </div>
              <b-collapse :id="`accordion-${data}`">
                <div>
                  <span
                    class="properties-list__modal-content-code-trackingcode"
                  >
                    {{ $t("accountManagement.trackingCode") }}
                  </span>
                  <pre>
                        <code class="properties__tracking-code" v-text='displaySdkSnippet.ANDROID[data]' />
                      </pre>
                  <div
                    class="properties-list__modal-content-code-copycode"
                    @click="
                      doCopy(
                        displaySdkSnippet.ANDROID[data],
                        `Bisko ${data} Android SDK`
                      )
                    "
                    :id="`copy-${data}-code`"
                  >
                    <span>{{ $t("accountManagement.copyToClipboard") }}</span>
                    <GjIcon name="Copy" class="mr-50" size="22" />
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-tab>
          <b-tab id="amp" :title="$t('accountManagement.Amp')">
            <div class="documentation__info-text mb-1">
              <span
                >{{ $t("accountManagement.ampSdkDocumentationLink") }}
                <a
                  :href="`${whitelabel.documentationUrl}/ampsdk`"
                  target="_blank"
                  >{{ $t("Documentation").toLowerCase() }}</a
                >.</span
              >
            </div>
            <span class="properties-list__modal-content-code-trackingcode">{{
              $t("accountManagement.trackingCode")
            }}</span>
            <pre>
                  <code class="properties__tracking-code amp__tracking-code" v-text='displaySdkSnippet.AMP' />
                </pre>
            <div
              class="properties-list__modal-content-code-copycode"
              @click="doCopy(displaySdkSnippet.AMP, 'Bisko AMP SDK')"
              id="copy-amp-code"
            >
              <span>{{ $t("accountManagement.copyToClipboard") }}</span>
              <GjIcon name="Copy" class="mr-50" size="22" />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {whitelabel} from "@/utils";
import {GjIcon, showToast} from '@nodus/utilities-front';
import {useClipboard} from '@vueuse/core';
import {BCollapse, BModal, BTab, BTabs} from 'bootstrap-vue';

export default {
  name: "TrackingCodeModal",
  props: {
    modalData: Object,
    displaySdkSnippet: Object,
    webSdkId: [String, Number],
  },
  components: {
    BModal,
    BTabs,
    BTab,
    BCollapse,
    GjIcon,
    // eslint-disable-next-line vue/no-unused-components
  },
  setup() {
    const { copy } = useClipboard();

    const doCopy = (code, type) => {
      copy(code);
      showToast("success", `${type} copied!`);
    };

    return {
      doCopy,
      whitelabel
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/views/properties/tracking-code-modal.scss";
</style>
