export default [
  {
    path: '/bisko/:organizationId/organization/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Organization'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  }
]
