import { users } from '@/api';

const roles = ['owner', 'admin', 'analyst'];

const clearErrorsMessages = (state) => {
  Object.keys(state).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    if (state[key].errors) state[key].errors = [];
  });
};

const handleSave = async (state, props, store) => {
  let validRequest = true;
  let propertyIds;
  const {
    typeOfGrid,
    tabData: { rowToEdit },
  } = props;

  const role = roles.indexOf(state.role.value) + 1;
  if (!state.isEditing)
    propertyIds = state.propertyIds.value.reduce((acc, curr) => {
      acc.push(curr.id);

      return acc;
    }, []);

  if (typeOfGrid === 'organization') {
    if (state.isEditing) {
      const response = await users.updateOrganization(
        state.organizationCode,
        rowToEdit.userId,
        role
      );
      const {
        data: { errors, success },
      } = response;

      if (success) {
        store.dispatch('users/getOrganizationUsers', {
          id: state.organizationCode,
        });
      } else {
        validRequest = false;
        Object.keys(errors).forEach((key) => {
          state[key].errors = errors[key];
        });
      }
    } else {
      const response = await users.addOrganization(
        state.organizationCode,
        role,
        state.email.value
      );

      const {
        data: { errors, success },
      } = response;

      if (success) {
        store.dispatch('users/getOrganizationUsers', {
          id: state.organizationCode,
        });
        store.dispatch('users/getInvitationUsers', {
          id: state.organizationCode,
        });
      } else {
        validRequest = false;
        Object.keys(errors).forEach((key) => {
          state[key].errors = errors[key];
        });
      }
    }
  }

  if (typeOfGrid === 'properties') {
    if (state.isEditing) {
      const response = await users.updateProperty(
        state.organizationCode,
        rowToEdit.propertyId,
        rowToEdit.userId,
        role
      );
      const {
        data: { errors, success },
      } = response;

      if (success) {
        store.dispatch('users/getPropertyUsers', {
          id: state.organizationCode,
        });
      } else {
        validRequest = false;
        Object.keys(errors).forEach((key) => {
          state[key].errors = errors[key];
        });
      }
    } else {
      const response = await users.addProperty(
        state.organizationCode,
        propertyIds,
        state.email.value,
        role
      );
      const {
        data: { errors, success },
      } = response;

      if (success) {
        store.dispatch('users/getPropertyUsers', {
          id: state.organizationCode,
        });
        store.dispatch('users/getInvitationUsers', {
          id: state.organizationCode,
        });
      } else {
        validRequest = false;
        Object.keys(errors).forEach((key) => {
          state[key].errors = errors[key];
        });
      }
    }
  }

  if (typeOfGrid === 'apikeys') {
    const expiryDate = state.expiryDate.value || null;

    const {
      data: { errors, success, data },
    } = await users.addAPIKey(
      state.organizationCode,
      role,
      state.name.value,
      expiryDate
    );

    if (success) {
      store.dispatch('users/getAPIKeys', { id: state.organizationCode });
      store.commit('users/SET_APIKEY_TOKEN', data.token);
    } else {
      validRequest = false;
      Object.keys(errors).forEach((key) => {
        state[key].errors = errors[key];
      });
    }
  }

  return validRequest;
};

const handleDelete = async (tableFormat, organizationId) => {
  const { entityName, IDtoDelete } = tableFormat;

  if (entityName === 'Organization') {
    await users.deleteOrganization(organizationId, IDtoDelete);
  }
  if (entityName === 'Properties') {
    await users.deleteProperty(
      organizationId,
      tableFormat.property,
      IDtoDelete
    );
  }

  if (entityName === 'Invitations') {
    await users.deleteInvitation(organizationId, IDtoDelete);
  }

  if (entityName === 'APIKeys') {
    await users.revokeAPIKey(organizationId, IDtoDelete);
  }
};

const resendActivation = async (typeOfGrid, id, organizationId, store) => {
  let validRequest = true;
  if (typeOfGrid === 'invitations') {
    await users.resendActivation(organizationId, id);
  }

  if (typeOfGrid === 'apikeys') {
    const {
      data: { success, data },
    } = await users.regenerateApiKey(organizationId, id);
    if (success) store.commit('users/SET_APIKEY_TOKEN', data.token);
    else validRequest = false;
  }
  return validRequest;
};

export { clearErrorsMessages, handleDelete, handleSave, resendActivation };
