<script>
import { t } from '@/libs/i18n/utils';
import { DropdownActions, ReadMore } from '@nodus/utilities-front';
import { getCurrentInstance } from 'vue';

export default {
  name: 'TopicsRow',
  components: {
    DropdownActions,
    ReadMore,
  },
  props: {
    data: {
      type: [Array, Object],
    },
    isLoading: Boolean,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { row } = props.data;

    const actionItems = [
      {
        id: 'resend-invitation',
        icon: 'Refresh',
        text: t('accountManagement.userRoles.resend'),
        disabled: !row.canResend || props.isLoading,
        action: () =>
          vm.$emit('trigger-activation', 'invitations', row.invitationCode),
      },
      {
        id: 'delete-invitation',
        icon: 'Delete',
        text: t('Delete'),
        type: 'danger',
        modal: 'modal-small-delete',
        disabled: !row.canDelete,
        action: () =>
          vm.$store.commit('users/SET_ID_TO_DELETE', {
            id: row.invitationCode,
            format: 'Invitations',
          }),
      },
    ];

    return {
      actionItems,
    };
  },
};
</script>
<template>
  <div>
    <!-- Column: Email -->
    <span v-if="data.column.field === 'email'">
      <div class="flex-elem user-roles__actions">
        <ReadMore :text="data.row.email" />
      </div>
    </span>

    <!-- Column: Properties -->
    <span v-else-if="data.column.field === 'properties'">
      <ReadMore :text="data.row.properties" />
    </span>

    <!-- Column: Status -->
    <span v-else-if="data.column.field === 'status'">
      <div class="flex-elem user-roles__actions">
        <span>{{ data.row.status }}</span>
      </div>
    </span>

    <!-- Column: Actions -->
    <span v-else-if="data.column.field === 'actions'">
      <DropdownActions :items="actionItems" />
    </span>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>
