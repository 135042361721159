// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__organization .users__container .user-roles {
  min-height: calc(100vh - 235px) !important;
}
.bisko-app__organization .users__container .user-roles-tables {
  min-height: inherit;
}
.bisko-app__organization .users__container .user-roles-tables .vgt-inner-wrap {
  min-height: calc(100vh - 235px) !important;
}
.bisko-app__organization .users__container .user-roles-tables .custom-table-empty-content {
  min-height: calc(100vh - 371px) !important;
}
.bisko-app__organization .users__container .audience__section {
  padding: 0 24px;
}
.bisko-app__organization .users__container .tabs .nav {
  border-radius: 0px;
}
.bisko-app__organization .users__container .tabs .nav-tabs .nav-link {
  padding: 0.61rem 0;
  margin-left: 24px;
}
.bisko-app__organization .users__container .create__button {
  margin-left: 0;
}
.bisko-app__organization .user-roles {
  min-height: calc(100vh - 180px);
  position: relative;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  min-width: 700px;
}
.bisko-app__organization .user-roles__actions {
  display: block;
  text-align: left;
}
.bisko-app__organization .user-roles__actions > div > button {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: #ffffff;
}
.bisko-app__organization .user-roles__actions > div > button > svg {
  cursor: pointer;
  color: #7483A1;
}
.bisko-app__organization .user-roles__actions > div > button[disabled] > svg {
  color: #e0e5eb;
}
.bisko-app__organization .user-roles > .tabs ul li {
  padding-left: 24px;
  color: #052d61;
}
.bisko-app__organization .user-roles > .tabs ul li a {
  font-size: 0.875rem;
  color: #052d61;
  padding: 16px 0;
}
.bisko-app__organization .user-roles > .tabs ul li a.nav-link[aria-selected=true] {
  color: #667c99;
}
.bisko-app__organization .user-roles > .tabs .nav {
  border-radius: 0;
}
.bisko-app__organization .user-roles > .tabs .tab-content {
  padding: 0 24px;
}
.bisko-app__organization .user-roles__custom-search.d-flex.align-right {
  justify-content: flex-end;
}
.bisko-app__organization .user-roles__custom-search {
  margin-bottom: 16px;
}
.bisko-app__organization .user-roles__custom-search .table__search {
  margin-bottom: 0;
}
.bisko-app__organization .delete-modal .modal-content .modal-body {
  font-size: 14px;
  padding: 2rem 1rem;
  text-align: center;
  color: #052D61;
}
.bisko-app__organization .delete-modal .modal-content .modal-footer > .btn {
  width: 80px;
  margin: 0 0 0 1rem;
}
.bisko-app__organization .modal-header {
  background-color: white !important;
  border-bottom: 1px solid #E0E5EB !important;
}
.bisko-app__organization .modal-footer > .btn {
  border-color: #E0E5EB !important;
  color: #052D61;
  width: 80px;
  margin: 0 0 0 1rem;
}
.bisko-app__organization .not-sortable-headers::after,
.bisko-app__organization .not-sortable-headers::before {
  display: none !important;
}
.bisko-app__organization .flex-elem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bisko-app__organization .block-elem {
  width: 100%;
}
.bisko-app__organization .actions__dropdown {
  max-height: 30px;
  display: flex;
  align-items: center;
  max-width: 40px;
}
.bisko-app__organization .apikeys__actions .status-and-actions ul.dropdown-menu {
  min-width: 150px;
  transform: translate3d(-125px, 10px, 0px) !important;
  border-radius: 4px;
}
.bisko-app__organization .tooltip-inner {
  font-size: 13px !important;
  text-align: left;
  max-width: 450px;
  background: #4B4B4B !important;
}
.bisko-app__organization .user-roles-tables__modal-content-selectproperty .vs__dropdown-menu {
  max-height: 250px;
}
.user-roles__modal .role-options__container {
  line-height: 18px;
  margin-bottom: 8px;
}
.user-roles__modal .role-options__container .role-options {
  display: inline-block;
}
.user-roles__modal .role-options__container .custom-control-label {
  margin-bottom: 2px;
  color: #052D61;
  font-size: 14px !important;
}
.user-roles__modal .role-options__container .custom-control input:checked ~ label {
  color: #052D61;
}
.user-roles__modal .custom-control-input[disabled] ~ .custom-control-label,
.user-roles__modal .custom-control-input:disabled ~ .custom-control-label {
  color: #6e6e6e;
}
.user-roles__modal .custom-control-input[disabled] ~ .custom-control-label::after,
.user-roles__modal .custom-control-input:disabled ~ .custom-control-label::after {
  border-radius: 50%;
}
.user-roles__modal .user-roles__info-icon {
  color: #667C99;
  margin: 0 0 2px 6px;
}
.user-roles__modal .error__info-message {
  font-size: 13px;
  color: #e34850;
  height: 18px;
}
.user-roles__modal .modal-footer .btn.btn-primary {
  width: 84px;
}
.user-roles__modal .modal-footer .btn.btn-primary:hover {
  background-color: #0062cc !important;
}
.api-keys__modal .modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.api-keys__modal .modal-body {
  padding: 12px 24px 32px;
  font-size: 14px;
}
.api-keys__modal .api-keys__modal--title {
  font-size: 18px;
  color: #052D61;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.api-keys__modal .api-keys__modal--title .api-header__image {
  color: #36B37E;
}
.api-keys__modal .api-keys__modal--key {
  background: #F6F7F9;
  padding: 24px;
  text-align: start;
  color: #667C99;
  margin: 48px 0 24px;
  border-radius: 8px;
}
.api-keys__modal .api-keys__modal--key div:not(:last-child) {
  margin-bottom: 18px;
}
.api-keys__modal .api-keys__modal--key .api-key__content {
  word-break: break-all;
  color: #052D61;
}
.api-keys__modal .api-keys__modal--key .api-key__copy {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.api-keys__modal .api-keys__modal--key .api-key__copy span {
  vertical-align: middle;
}
.api-keys__modal .api-keys__modal--info {
  text-align: left;
}
.api-keys__modal .api-keys__modal--info .api-info__title {
  color: #052D61;
  margin-bottom: 4px;
}
.api-keys__modal .api-keys__modal--info span {
  color: #667C99;
}
.bisko-app__organization .api-keys__expired--text {
  color: #A3B0C2;
}
.bisko-app__organization .user-roles-tables {
  width: 100%;
  min-height: inherit;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive {
  overflow-x: none;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table {
  font-size: 16px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead {
  background: white;
  border-bottom: 1px solid #edeff3;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th {
  font-weight: 400;
  position: relative;
  cursor: pointer;
  line-height: 18px;
  padding: 8px 12px;
  padding-left: 16px;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th span {
  font-size: 14px;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th:before {
  content: "";
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  height: 0;
  width: 0;
  top: 48%;
  margin-bottom: -4px;
  left: 2px;
  border: solid #667c99;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th:after {
  content: "";
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: absolute;
  height: 0;
  width: 0;
  top: 48%;
  margin-top: -4px;
  left: 2px;
  border: solid #667c99;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th.sorting-desc:before {
  border: solid #409eff;
  border-width: 0 1px 1px 0;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th.sorting-asc:after {
  border: solid #409eff;
  border-width: 0 1px 1px 0;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th.sortable button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table thead tr th.sortable button:focus-visible {
  outline: none;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr {
  border-bottom: 1px solid #edeff3;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td {
  padding: 16px 12px;
  padding-left: 16px;
  vertical-align: middle;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td span {
  font-size: 14px;
  line-height: 18px;
  color: #11254a;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td span.dark-gray-color {
  color: #052d61;
  cursor: pointer;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td .block-elem {
  width: 100%;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td .block-elem > span {
  display: block;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr td .block-elem > span:not(:first-child) {
  padding-top: 10px;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table tbody tr:last-child {
  border-bottom: none;
}
@media (max-width: 600px) {
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-responsive table table.vgt-table tbody tr td {
    padding: 21px 12px;
}
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-inner-wrap {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 180px);
  box-shadow: none;
}
.bisko-app__organization .user-roles-tables .vgt-wrap .vgt-inner-wrap > div:nth-child(2) {
  flex-grow: 1;
}
.bisko-app__organization .name-column {
  min-width: 200px !important;
}
.bisko-app__organization .vgt-right-align {
  text-align: left;
}
.bisko-app__organization .status-and-actions {
  color: #7483a1;
}
.bisko-app__organization .status-and-actions .text-body {
  color: #7483a1 !important;
}
.bisko-app__organization .status-and-actions .text-body[disabled] {
  color: #e0e5eb;
}
.user-roles__modal-content input {
  font-size: 0.857rem;
}
.user-roles__modal-content input:disabled {
  background-color: #e0e5eb;
}
.user-roles__modal-content-roles {
  margin-top: 8px;
}
.user-roles__modal-content-roles > label {
  color: #667c99;
  margin-bottom: 10px;
}
.user-roles__modal-content-roles span {
  color: #052d61;
}
.user-roles__modal-content-roles > div > div:not(:last-child) {
  margin-bottom: 18px;
}
.user-roles__modal-content-roles .error__info-message > span {
  color: #e34850 !important;
}
.user-roles__modal-content-date-label {
  color: #667c99;
}
.bisko-app__organization .user-roles .table__wrapper .segment__avatar--padding {
  padding: 8px 10px;
  margin: 0 10px 0 0;
}
.bisko-app__organization .user-roles .table__wrapper .vgt-wrap .vgt-inner-wrap {
  min-height: calc(100vh - 230px);
}
.bisko-app__organization .user-roles .table__wrapper .vgt-wrap .custom-table-empty-content {
  min-height: calc(100vh - 375px);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
