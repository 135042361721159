import axios from '@axios'

const properties = {
  addNew: (organizationId = '', requestObject = {}) =>
    axios.post(`/api/v1/${organizationId}/properties`, requestObject),
  delete: (organizationId = '', propertyId = '') =>
    axios.delete(`/api/v1/${organizationId}/properties/${propertyId}`),
  update: (organizationId = '', propertyId = '', requestObject) =>
    axios.patch(
      `/api/v1/${organizationId}/properties/${propertyId}`,
      requestObject
    ),
}

const users = {
  updateOrganization: (organizationId, userId, role) =>
    axios.patch(`/api/v1/${organizationId}/users/${userId}`, {
      role,
    }),
  addOrganization: (organizationId, role, email) =>
    axios.post(`/api/v1/${organizationId}/users`, {
      email,
      role,
    }),
  updateProperty: (organizationId, propertyId, userId, role) =>
    axios.patch(
      `/api/v1/${organizationId}/properties/${propertyId}/users/${userId}`,
      {
        role,
      }
    ),
  addProperty: (organizationId, propertyIds, email, role) =>
    axios.post(`/api/v1/${organizationId}/properties/users`, {
      propertyIds,
      email,
      role,
    }),
  revokeAPIKey: async (organizationId, apiKeyId) => {
    await axios.patch(`/api/v1/${organizationId}/apikeys/revoke`, { apiKeyId })
  },
  addAPIKey: (organizationId, role, name, expiryDate) =>
    axios.post(`/api/v1/${organizationId}/apikeys`, {
      role,
      name,
      expiryDate,
    }),
  regenerateApiKey: (organizationId, apiKeyId) =>
    axios.patch(`/api/v1/${organizationId}/apikeys/regenerate`, { apiKeyId }),
  deleteOrganization: async (organizationId, userId) => {
    await axios.delete(`/api/v1/${organizationId}/users/${userId}`)
  },
  deleteProperty: async (organizationId, propertyId, userId) => {
    await axios.delete(
      `/api/v1/${organizationId}/properties/${propertyId}/users/${userId}`
    )
  },
  deleteInvitation: async (organizationId, invitationCode) => {
    await axios.delete(
      `/api/v1/${organizationId}/users/invitations/${invitationCode}`
    )
  },
  resendActivation: async (organizationId, invitationCode) => {
    await axios.patch(`/api/v1/${organizationId}/users/invitations`, {
      invitationCode,
    })
  },
}

export { properties, users }
