var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-roles-tables__modal" },
    [
      _c(
        "b-modal",
        {
          ref: "addUserModal",
          attrs: {
            id: "modal-lg-add-user",
            "modal-class": "modal__body__text--left user-roles__modal",
            "ok-disabled": _vm.isLoading,
            "cancel-title": _vm.$t("Cancel"),
            "cancel-variant": "outline-secondary",
            centered: "",
            size: "lg",
            title: _vm.title,
            "no-enforce-focus": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: {
            cancel: _vm.onModalClose,
            close: _vm.onModalClose,
            ok: _vm.triggerSave,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function () {
                return [
                  _vm.isLoading
                    ? _c("b-spinner", {
                        staticClass: "mr-50",
                        attrs: { small: "" },
                      })
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      _vm._s(!_vm.isEditing ? _vm.$t("Add") : _vm.$t("Save"))
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "user-roles-tables__modal-content" }, [
            _vm.typeOfGrid !== "apikeys"
              ? _c(
                  "div",
                  { staticClass: "mb-50 input__wrapper" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("Organization")))]),
                    _c("b-form-input", {
                      staticClass: "form__input form__input--dark",
                      attrs: {
                        id: "organization-name",
                        placeholder: _vm.$t("Organization"),
                        type: "text",
                        readonly: "",
                      },
                      model: {
                        value: _vm.organizationName,
                        callback: function ($$v) {
                          _vm.organizationName = $$v
                        },
                        expression: "organizationName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.typeOfGrid === "properties"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "user-roles-tables__modal-content-selectproperty input__wrapper mb-50",
                  },
                  [
                    _c("label", { staticClass: "mr-1" }, [
                      _vm._v(_vm._s(_vm.$t("property")) + " *"),
                    ]),
                    _vm.isEditing
                      ? _c("b-form-input", {
                          staticClass: "form__input form__input--dark",
                          attrs: {
                            id: "property-selected",
                            placeholder: _vm.$t("property"),
                            type: "text",
                            readonly: "",
                          },
                          model: {
                            value: _vm.propertyIds.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.propertyIds, "value", $$v)
                            },
                            expression: "propertyIds.value",
                          },
                        })
                      : _c("MultipleProperties", {
                          attrs: {
                            id: "user-properties",
                            options: _vm.allProperties,
                            label: "title",
                            "tags-to-show": 3,
                            "no-debounce": "",
                            classes: ["multiple-properties--three"],
                            "all-selected-classes": [
                              "multiple-properties--all",
                            ],
                            "invalid-input": _vm.propertyIds.errors.length > 0,
                          },
                          on: { "update-data": _vm.updateProperties },
                        }),
                    _vm._l(_vm.propertyIds.errors, function (error, errIndex) {
                      return _c(
                        "div",
                        {
                          key: `err-${errIndex}`,
                          staticClass: "error__info-message",
                        },
                        [_c("span", [_vm._v(_vm._s(error))])]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.typeOfGrid !== "apikeys"
              ? _c(
                  "div",
                  { staticClass: "mb-50 input__wrapper" },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("EmailAddress")) + " *"),
                    ]),
                    _c("b-form-input", {
                      staticClass: "form__input",
                      class: {
                        "form__input--dark": _vm.isEditing,
                        "form__input--invalid": _vm.email.errors.length > 0,
                      },
                      attrs: {
                        id: "email",
                        placeholder: _vm.$t("emailExample"),
                        type: "text",
                        readonly: _vm.isEditing,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.handleFormInput(null, [_vm.email])
                        },
                      },
                      model: {
                        value: _vm.email.value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.email,
                            "value",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "email.value",
                      },
                    }),
                    _vm._l(_vm.email.errors, function (error, errIndex) {
                      return _c(
                        "div",
                        { key: errIndex, staticClass: "error__info-message" },
                        [_c("span", [_vm._v(_vm._s(error))])]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.typeOfGrid === "apikeys"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "mb-50 input__wrapper" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("Name")) + " *")]),
                      _c("b-form-input", {
                        staticClass: "form__input",
                        class: {
                          "form__input--invalid": _vm.name.errors.length > 0,
                        },
                        attrs: {
                          id: "api-key",
                          placeholder: _vm.$t("enterApiKey"),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.handleFormInput(null, [_vm.name])
                          },
                        },
                        model: {
                          value: _vm.name.value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.name,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "name.value",
                        },
                      }),
                      _vm._l(_vm.name.errors, function (error, errIndex) {
                        return _c(
                          "div",
                          {
                            key: `err-${errIndex}`,
                            staticClass: "error__info-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "user-roles-tables__modal-content-expire-date mb-2",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "user-roles-tables__modal-content-date-label",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "accountManagement.userRoles.expirationDate"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        { attrs: { id: "tooltip-date" } },
                        [
                          _c("GjIcon", {
                            staticClass: "user-roles__info-icon",
                            attrs: { name: "Info", size: "20" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tooltip",
                        {
                          attrs: {
                            target: "tooltip-date",
                            triggers: "hover",
                            placement: "bottom",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "accountManagement.userRoles.expirationDateInfo"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("b-form-datepicker", {
                        staticClass: "form__input",
                        class: {
                          "form__input--invalid":
                            _vm.expiryDate.errors.length > 0,
                        },
                        attrs: {
                          id: "expiry-date",
                          "calendar-width": "100%",
                          min: _vm.currentDate,
                          "date-format-options": {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            weekday: "short",
                          },
                        },
                        on: {
                          input: function ($event) {
                            return _vm.handleFormInput(null, [_vm.expiryDate])
                          },
                        },
                        model: {
                          value: _vm.expiryDate.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.expiryDate, "value", $$v)
                          },
                          expression: "expiryDate.value",
                        },
                      }),
                      _vm._l(_vm.expiryDate.errors, function (error, errIndex) {
                        return _c(
                          "div",
                          {
                            key: `err-${errIndex}`,
                            staticClass: "error__info-message",
                          },
                          [_c("span", [_vm._v(_vm._s(error))])]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "user-roles-tables__modal-content-roles" },
              [
                _c("label", { staticClass: "mr-1" }, [
                  _vm._v(_vm._s(_vm.$t("Role")) + " *"),
                ]),
                _vm._l(_vm.options, function (option, index) {
                  return _c("div", { key: index }, [
                    _vm.typeOfGrid !== "apikeys" || option.value !== "owner"
                      ? _c(
                          "div",
                          { staticClass: "role-options__container" },
                          [
                            _c(
                              "b-form-radio",
                              {
                                staticClass: "role-options",
                                attrs: {
                                  id: `${option.text}-role`,
                                  value: option.value,
                                  disabled: option.disabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleFormInput(null, [_vm.role])
                                  },
                                },
                                model: {
                                  value: _vm.role.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.role, "value", $$v)
                                  },
                                  expression: "role.value",
                                },
                              },
                              [_vm._v(" " + _vm._s(option.text) + " ")]
                            ),
                            _c(
                              "span",
                              {
                                attrs: {
                                  id: "option-" + _vm.typeOfGrid + option.text,
                                },
                              },
                              [
                                _c("GjIcon", {
                                  staticClass: "mr-50 user-roles__info-icon",
                                  attrs: { name: "Info", size: "20" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target:
                                    "option-" + _vm.typeOfGrid + option.text,
                                  triggers: "hover",
                                  placement: "bottom",
                                  "custom-class": "tooltip__role-information",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showRoleInformation(
                                        _vm.typeOfGrid + option.text
                                      )
                                    ) +
                                    ". "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                _vm._l(_vm.role.errors, function (error, errIndex) {
                  return _c(
                    "div",
                    {
                      key: `err-${errIndex}`,
                      staticClass: "error__info-message",
                    },
                    [_c("span", [_vm._v(_vm._s(error))])]
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }