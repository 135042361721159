var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "section",
        { staticClass: "form__wrapper" },
        [
          _c(
            "div",
            { staticClass: "create-property__container" },
            [
              _c(
                "b-form",
                {
                  staticClass: "properties-form__container",
                  attrs: { novalidate: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "b-form-row",
                    { staticClass: "properties-form__row-container" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "input__wrapper", attrs: { lg: "12" } },
                        [
                          _c("label", { attrs: { for: "Organization" } }, [
                            _vm._v(_vm._s(_vm.$t("Organization")) + " *"),
                          ]),
                          _c("b-form-input", {
                            staticClass: "form__input form__input--dark",
                            attrs: {
                              id: "readOnlyInput",
                              value: _vm.organizationName,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "input__wrapper", attrs: { lg: "12" } },
                        [
                          _c("label", { attrs: { for: "input-invalid1" } }, [
                            _vm._v(_vm._s(_vm.$t("Name")) + " *"),
                          ]),
                          _c("b-form-input", {
                            staticClass: "form__input",
                            class: {
                              "form__input--invalid":
                                _vm.propertyObject.name.errors.length > 0,
                            },
                            attrs: {
                              id: "property-name",
                              placeholder: _vm.$t(
                                "Enter application name here"
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.formState, [
                                  _vm.propertyObject.name,
                                ])
                              },
                            },
                            model: {
                              value: _vm.propertyObject.name.value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.propertyObject.name,
                                  "value",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "propertyObject.name.value",
                            },
                          }),
                          _vm._l(
                            _vm.propertyObject.name.errors,
                            function (error, errIndex) {
                              return _c(
                                "div",
                                {
                                  key: errIndex,
                                  staticClass: "error__info-message",
                                },
                                [_c("span", [_vm._v(_vm._s(error))])]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        { staticClass: "input__wrapper", attrs: { lg: "12" } },
                        [
                          _c("label", { attrs: { for: "textarea-default" } }, [
                            _vm._v(_vm._s(_vm.$t("Description")) + " *"),
                          ]),
                          _c("b-form-textarea", {
                            staticClass: "form__input form__input-textarea",
                            class: {
                              "form__input--invalid":
                                _vm.propertyObject.description.errors.length >
                                0,
                            },
                            attrs: {
                              id: "property-description",
                              placeholder: _vm.$t(
                                "Enter application description here"
                              ),
                              rows: "2",
                              "no-resize": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.handleFormInput(_vm.formState, [
                                  _vm.propertyObject.description,
                                ])
                              },
                            },
                            model: {
                              value: _vm.propertyObject.description.value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.propertyObject.description,
                                  "value",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "propertyObject.description.value",
                            },
                          }),
                          _vm._l(
                            _vm.propertyObject.description.errors,
                            function (error, errIndex) {
                              return _c(
                                "div",
                                {
                                  key: errIndex,
                                  staticClass: "error__info-message",
                                },
                                [_c("span", [_vm._v(_vm._s(error))])]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Footer", {
            attrs: {
              "is-loading": _vm.formState.isLoading,
              submitted: _vm.formState.isLoading,
              "cancel-id": `cancel-create-property`,
              "create-id": `add-property`,
              "edit-mode": !!_vm.propertyId,
            },
            on: {
              back: function ($event) {
                return _vm.cancel()
              },
              create: function ($event) {
                !_vm.propertyId ? _vm.createProperty() : _vm.triggerSaveModal()
              },
            },
          }),
          _c("SaveChangesModal", {
            attrs: { type: _vm.$t("property") },
            on: {
              save: function ($event) {
                return _vm.createProperty()
              },
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }