import axiosIns from '@/libs/axios';
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front';

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          width: '25%',
        },
        {
          label: 'Description',
          field: 'description',
          width: '30%',
        },
        {
          label: 'Created Date',
          field: 'insertDateTime',
          width: '38%',
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '7%',
          sortable: false,
          thClass: 'not-sortable-headers',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
    },
    name: '',
    userProperties: [],
    canAddProperty: null,
    IDToDelete: null,
    property: '',
  },
  getters: {
    allProperties: (state) => state.table,
    allUserProperties: (state) => state.userProperties,
    getCanAdd: (state) => state.canAddProperty,
    organizationName: (state) => state.name,
    propertyToDelete: (state) => state.IDToDelete,
    propertySelected: (state) => state.property,
  },
  actions: {
    async getProperties({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      );
      const response = await axiosIns.get(
        `/api/v1/${id}/properties?${queryParamString}`
      );

      commit(
        'SET_ORGANIZATION_NAME',
        destructJSONObject(response, 'organizationName')
      );
      commit('SET_TABLE', destructJSONObject(response, 'properties'));
      commit(
        'SET_TOTAL_PROPERTIES',
        destructJSONObject(response, 'totalCount')
      );
      commit(
        'SET_ADD_PROPERTIES_CHECK',
        destructJSONObject(response, 'canAddProperty')
      );
    },
    async getProperty({ commit }, { id, propertyId }) {
      const response = await axiosIns.get(
        `/api/v1/${id}/properties/${propertyId}`
      );
      commit(
        'SET_ORGANIZATION_NAME',
        destructJSONObject(response, 'organizationName')
      );
      return response.data.data;
    },
    async getByUserRole({ commit }, { id }) {
      const response = await axiosIns.get(`/api/v1/${id}/user/properties`);

      commit('SET_USER_PROPERTIES', destructJSONObject(response, 'properties'));
    },
  },
  mutations: {
    SET_TABLE(state, rows) {
      state.table.rows = rows;
    },
    SET_TOTAL_PROPERTIES(state, totalCount) {
      state.table.totalRecords = totalCount;
    },
    SET_ADD_PROPERTIES_CHECK(state, canAddProperty) {
      state.canAddProperty = canAddProperty;
    },
    SET_ORGANIZATION_NAME(state, name) {
      state.name = name;
    },
    SET_USER_PROPERTIES(state, properties) {
      state.userProperties = properties;
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDToDelete = id;
    },
    SET_PROPERTY(state, property) {
      state.property = property;
    },
  },
};
