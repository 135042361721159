// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.properties-list__modal-content-id {
  position: relative;
  font-size: 0.875rem;
  margin-top: 8px;
  color: #7483a1;
}
.properties-list__modal-content-id > input {
  font-size: inherit;
  cursor: pointer;
  background: #e0e5eb;
  margin-top: 5px;
  border: 1px solid #e0e5eb;
  box-sizing: border-box;
  border-radius: 4px;
  transition: border 0.4s ease-in-out;
}
.properties-list__modal-content-id > input:focus {
  outline: #ffffff;
}
.properties-list__modal-content-id > svg {
  position: absolute;
  pointer-events: none;
  top: 34px;
  right: 0;
}
.properties-list__modal-content-code {
  margin-top: 40px;
}
.properties-list__modal-content-code-trackingcode {
  font-size: 0.875rem;
  color: #667c99;
  margin-bottom: 5px;
}
.properties-list__modal-content-code-copycode {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #052d61;
  width: -moz-fit-content;
  width: fit-content;
}
.properties-list__modal-content-code-copycode > svg {
  position: absolute;
  left: 0;
  pointer-events: none;
}
.properties-list__modal-content-code-copycode > span {
  font-size: 0.875rem;
  margin-left: 30px;
  color: #052d61;
}
.properties-list__modal-content-code pre {
  background: #ffffff;
  margin: -15px 0px;
}
.properties-list__modal-content-code code {
  border: 1px solid #e0e5eb;
  display: block;
  white-space: pre;
  margin-bottom: -20px;
  padding: 12px 0px 15px 12px;
  font-size: 0.875rem;
  color: #052d61;
}
.properties-list__modal-content-code .nav-tabs {
  border-radius: 0;
}
.properties-list__modal-content-code .nav-tabs:first-child {
  padding-left: 8px;
}
.properties-list__modal-content-code .nav-tabs .nav-link {
  margin-right: 24px;
  padding: 0 0 16px 0;
}
.properties-list__modal-content .properties__tracking-code {
  overflow: auto;
  box-shadow: 0px 2px 16px rgba(5, 45, 97, 0.12);
  border-radius: 5px;
  color: #052d61;
  max-height: 191px;
  position: relative;
}
.mobile__wrapper {
  padding: 0 10px;
  color: #667c99;
}
.mobile__wrapper-heading {
  margin-bottom: 10px;
}
.tracking-code__header .modal-header h5 {
  white-space: pre-line;
  font-size: 14px !important;
}
.tracking-code__header .modal-header h5::first-line {
  font-size: 16px;
}
.documentation__info-text {
  font-size: 13px;
  line-height: 18px;
  color: #7483a1;
  display: flex;
  align-items: center;
  text-align: left;
}
.documentation__info-text a {
  color: #052d61;
  text-decoration: underline;
}
.documentation__info-text a:hover {
  color: #052d61;
}
.documentation__info-text svg {
  min-width: 18px;
}
.mr-50 {
  margin-right: 0.5rem !important;
}
.presets-container__expandable-heading {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e5eb;
  font-size: 14px;
  padding: 20px 0 10px 0;
  position: relative;
}
.presets-container__expandable-heading:after {
  content: "";
  border: solid #667c99;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: absolute;
  right: 10px;
}
.presets-container__expandable-heading[aria-expanded=true]:after {
  content: "";
  border: solid #667c99;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
